
import React, { useState, useEffect } from 'react';

import { apiConfig } from 'config/apiConfig'

import useFetchWithMsal from 'hooks/useFetchWithMsal';
import { ProgressIndicator, useProgressIndicator } from 'components/Elements/ProgressIndicator';
import { useCardroomContext } from 'features';
import DataTable from 'components/Elements/DataTable';
import ParticipantCell from 'components/Elements/DataTable/components/Cells/ParticipantCell';
import TextCell from 'components/Elements/DataTable/components/Cells/TextCell';
import { Card, CardActions, CardContent, Grid, Icon, useMediaQuery, useTheme } from '@mui/material';
import whiteCurved from "assets/images/curved-images/white-curved.jpeg";
import SoftBox from 'components/SoftBox';
import MiniStatisticsCard from 'components/Elements/MiniStatisticsCard';
import { useDialog } from 'components/Elements/Dialog/common';
import SoftButton from 'components/SoftButton';
import { useBookDataLoader } from '..';
import BookPlayerEditorDialog from './Dialogs';
import MiniActionCard from 'components/Elements/MiniActionCard';
import MoneyCell from 'components/Elements/DataTable/components/Cells/MoneyCell';



const BookPlayers = ({ cardroom, book }) => {

    function createObjectMap(entities) {
        const map = [];
        if (entities) {
            for (var i = 0; i < entities.length; i++) {
                map[entities[i].id] = entities[i];
            }
        }
        return map;
    }

    function preparePlayersForPresentation(players, agents, playerPools, rebates, table) {

        const agentMap = createObjectMap(agents);
        const playerPoolMap = createObjectMap(playerPools);
        const rebateMap = createObjectMap(rebates);

        let rows = [];

        for (var i = 0; i < players.length; i++) {
            let playerImage = players[i].name.substring(0, 1);
            let agent = agentMap[players[i].agentId] ? agentMap[players[i].agentId] : null;
            let pool = playerPoolMap[players[i].agentPlayerPoolId] ? playerPoolMap[players[i].agentPlayerPoolId] : null;
            let rebate = rebateMap[players[i].rebateScheduleId] ? rebateMap[players[i].rebateScheduleId] : null;

            rows.push({
                name: [players[i].name, { image: playerImage }],
                displayName: players[i].name,
                displayNameNoCase: players[i].name.toLowerCase(),
                nickname: players[i].nickname ? players[i].nickname : "N/A",
                appPlayerId: players[i].appPlayerId,
                agentName: agent ? agent.name : "None",
                agentId: agent ? agent.appAgentId : "None",
                poolName: pool ? pool.name : "None",
                sourceAppName: players[i].app ? players[i].app.name : "None",
                rebateName: rebate ? rebate.name : "None",
                makeup: players[i].currentMakeupAmount,
                player: players[i],
                rebate: rebate,
                agent: agent,
                pool: pool
            });
        }

        if (rows.length > 0) {
            rows.sort(function (a, b) {
                if (a.displayNameNoCase < b.displayNameNoCase) { return -1; }
                if (a.displayNameNoCase > b.displayNameNoCase) { return 1; }
                return 0;
            });
        }

        table.rows = rows;

        return table;
    }

    function GetEmptySchema(onOpenPlayerEdit) {
        return {
            columns: [
                {
                    Header: "Player",
                    accessor: "name",
                    Cell: ({ value: [name, data], row }) => (
                        <ParticipantCell image={data.image} color={data.color || "dark"} name={name} selectable={false} checked={false} participant={row && row.original && row.original.player ? row.original.player : null} onClick={onOpenPlayerEdit} />
                    ),
                },
                { Header: "Nickname", accessor: "nickname", Cell: ({ value }) => <TextCell value={value} /> },
                { Header: "Player Id", accessor: "appPlayerId", Cell: ({ value }) => <TextCell value={value} /> },
                { Header: "Agent", accessor: "agentName", Cell: ({ value }) => <TextCell value={value} /> },
                { Header: "Package", accessor: "poolName", Cell: ({ value }) => <TextCell value={value} /> },
                { Header: "Rebate Schedule", accessor: "rebateName", Cell: ({ value }) => <TextCell value={value} /> },
                { Header: "Makeup", accessor: "makeup", Cell: ({ value }) => <MoneyCell value={-1 * value} useColorScheme={true} prefix="$" /> },
                //{ Header: "Makeup", accessor: "rebate", Cell: ({ value }) => <IconCell value={value ? "Yes" : "No"} icon={value ? "check" : "block"} color={value ? "success" : "warning"} /> },
                // { Header: "Source", accessor: "sourceAppName", Cell: ({ value }) => <TextCell value={value} /> },
            ],
            rows: []
        };
    }

    const switchToMobileOnSize = "md";

    const theme = useTheme();
    const isMobileUi = !useMediaQuery(theme.breakpoints.up(switchToMobileOnSize));
    const isXs = !useMediaQuery(theme.breakpoints.up("sm"));


    const [players, setPlayers] = useState(null);
    const [agents, setAgents] = useState(null);
    const [playerPools, setPlayerPools] = useState(null);
    const [rebates, setRebates] = useState(null);

    const [playersTable, setPlayersTable] = useState(null);

    const [retryCounter, setRetryCounter] = useState(0);

    function handleRetry() {
        setRetryCounter(retryCounter + 1);
    }

    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "Loading club players...", handleRetry)

    const [context, actions, features] = useCardroomContext(cardroom);

    const [openPlayerEditorDialog, playerEditorDialogProps] = useDialog();

    const [currentPlayer, setCurrentPlayer] = useState(null);

    function openPlayerEditor(player) {
        if (!canEditPlayerProfile()) return;

        if (player) {
            setCurrentPlayer(player);
            openPlayerEditorDialog();
        }
    }

    function canEditPlayerProfile() {
        if (!context) return false;

        return context.isAllowed(actions.club.book.player.edit);
    }

    function playerHasbeenUpdated(p) {
        //setPlayersTable(null);

        if (playersTable && playersTable.rows) {

            const rebateMap = createObjectMap(rebates);

            for (var i = 0; i < playersTable.rows.length; i++) {
                if (playersTable.rows[i].player && playersTable.rows[i].player.id === p.id) {

                    //todo find rebate schedule in reabtes variable and assign from there
                    //not there alert(p.ebateSchedule);
                    // THERE -- use this to lookup rebate alert(p.rebateScheduleId);
                    let rs = rebateMap[p.rebateScheduleId];
                    if (rs) {
                        playersTable.rows[i].player.rebateSchedule = rs;
                        playersTable.rows[i].rebate = rs;
                        playersTable.rows[i].rebateName = rs.name;
                    }

                    playersTable.rows[i].makeup = p.currentMakeupAmount;
                    //alert("updated");
                    break;
                }
            }
        }

    }

    const bookRequest = useFetchWithMsal({
        scopes: apiConfig.book.scopes.read,
    });

    const bookDataLoader = useBookDataLoader();

    useEffect(() => {
        if (!players) {
            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading players...")
            showProgress();

            bookDataLoader.entity.loadPlayers(bookRequest, cardroom, book, true, (response) => {
                setPlayers(response);
                setPlayersTable(null);
            }, (ex) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load players, please try again...");
            });

        }
    }, [bookRequest.execute, players, retryCounter]) //cardroomListData 

    useEffect(() => {
        if (players && !agents) {
            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading agents...")
            showProgress();

            bookDataLoader.entity.loadAgents(bookRequest, cardroom, book, true, (response) => {
                setAgents(response);
            }, (ex) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load agents, please try again...");
            });

        }
    }, [bookRequest.execute, players, agents, retryCounter])


    useEffect(() => {
        if (agents && !playerPools) {
            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading packages...")
            showProgress();

            bookDataLoader.entity.loadAgentsPlayerPools(bookRequest, cardroom, book, agents, true, (response, errCount) => {
                setPlayerPools(response);
            }, (ex) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load packages, please try again...");
            });

        }
    }, [bookRequest.execute, agents, playerPools, retryCounter])

    useEffect(() => {
        if (players && playerPools && !rebates) {
            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading rebate schedules...")
            showProgress();

            bookDataLoader.entity.loadPlayerRebateSchedules(bookRequest, cardroom, book, true, (response) => {
                setRebates(response);
            }, (ex) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load rebate schedules, please try again...");
            });
        }
    }, [bookRequest.execute, players, playerPools, rebates, retryCounter])

    useEffect(() => {
        if (players && agents && rebates && playerPools && !playersTable) {
            //TODO: add playerPools
            setPlayersTable(preparePlayersForPresentation(players, agents, playerPools, rebates, GetEmptySchema(canEditPlayerProfile() ? openPlayerEditor : null)));
            progressIndicatorProps.close();
        }
    }, [players, agents, playerPools, rebates, playersTable]);


    function renderMobileRow(columns, row, key, collapsibleDetailAccessor) {
        const color = "secondary";

        const hasAgent = row.cells[4].value != "None";

        return <Card
            sx={({ functions: { linearGradient, rgba }, palette: { gradients } }) => ({
                backgroundImage: gradients[color]
                    ? `${linearGradient(
                        rgba(gradients[color].main, 0.85),
                        rgba(gradients[color].state, 0.85)
                    )}, url(${whiteCurved})`
                    : `${linearGradient(
                        rgba(gradients[color].main, 0.85),
                        rgba(gradients[color].state, 0.85)
                    )}, url(${whiteCurved})`,
                backgroundSize: "cover",
                backgroundPosition: "50%",
                overflow: "visible",
            })}
        >
            <CardContent>
                <SoftBox pt={2} textAlign="center">
                    <MiniStatisticsCard
                        bgColor="white"
                        title={{ text: row.cells[1].value, fontWeight: "medium" }}
                        count={row.cells[0].value[0]}
                        icon={{ color: "dark", component: "person" }}
                        direction={"left"}
                        useNumericFormatter={false}
                    />
                </SoftBox>
                {hasAgent ?
                    < SoftBox pt={2} textAlign="center">

                        <Grid container ml="auto" mr="auto" xs={12}>
                            <Grid item xs={6} pr={0.25} pt={{ xs: 1, sm: 0 }} pb={{ xs: 1, sm: 0 }}>
                                <MiniActionCard
                                    bgColor="white"
                                    title={{ text: row.original.agentName, fontWeight: "medium" }}
                                    count={{ text: row.original.poolName, useNumericFormatter: false }}
                                    icon={{ color: "dark", component: "support_agent" }}
                                    direction={"left"}
                                />
                            </Grid>
                            <Grid item xs={6} pr={0.25} pt={{ xs: 1, sm: 0 }} pb={{ xs: 1, sm: 0 }}>
                                <MiniActionCard
                                    bgColor="white"
                                    title={{ text: "makeup", fontWeight: "medium" }}
                                    count={{ text: row.original.makeup, prefix: "$", useNumberFormatter: true, color: row.original.makeup > 0 ? "error" : "success" }}
                                    icon={{ color: "dark", component: "support_agent" }}
                                    direction={"right"}

                                />
                            </Grid>
                        </Grid>

                    </SoftBox> : null}

            </CardContent>
            <CardActions>
                <Grid item xs={12} p={1}>
                    <SoftButton ml="auto" mr="auto"
                        variant="gradient"
                        color={"dark"}
                        fullWidth
                        disabled={!canEditPlayerProfile()}
                        onClick={() => openPlayerEditor(row.original.player)}
                    >
                        SETTINGS&nbsp;
                        <Icon sx={{ fontWeight: "bold" }}>settings</Icon>
                    </SoftButton>
                </Grid>
            </CardActions>

        </Card >;
    }

    return <Card>
        <ProgressIndicator {...progressIndicatorProps} />

        {(cardroom && book) && !progressIndicatorProps.visible ? <>
            {canEditPlayerProfile() ? <BookPlayerEditorDialog {...playerEditorDialogProps} cardroom={cardroom} book={book} agent={agents} players={players} player={currentPlayer} onPlayerChanged={playerHasbeenUpdated} /> : null}

            {playersTable ?
                <DataTable table={playersTable} canSearch entriesPerPage={{ defaultValue: 10, visible: !isMobileUi }} pagination={{ color: "dark" }}
                    onRenderRow={renderMobileRow} useCustomRenderSize={switchToMobileOnSize}
                /> : null}
        </> : null}
    </Card>;
};

export default BookPlayers