import React, { useState, useEffect, useLayoutEffect } from 'react';
import PropTypes from "prop-types";

import { apiConfig } from 'config/apiConfig'

import useFetchWithMsal from 'hooks/useFetchWithMsal';

import { AppBar, Card, FormControlLabel, Grid, Icon, ListItemIcon, ListItemText, Radio, RadioGroup, Tab, Tabs, Tooltip, tooltipClasses } from '@mui/material';
import { Formik, Form, ErrorMessage } from 'formik';
import FormField from 'components/Elements/Forms/FormField';
import SoftAvatar from 'components/SoftAvatar';
import FormSwitch from 'components/Elements/Forms/FormSwitch';
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

import { styled } from '@mui/material/styles';


import validations from "./schemas/validations";
import form from "./schemas/form";
import initialValues from "./schemas/initialValues";

import { ProgressIndicator, useProgressIndicator } from 'components/Elements/ProgressIndicator';

import { useCardroomContext } from 'features';
import ClubFeeSelector from '../../ClubFees/Selector';
import DataBoundList from 'components/Elements/DataBound/List';
import ReferralFeeSelector from '../../ReferralFees/Selector';
import { InvitationsEditor } from 'components/Elements/Invitation/Editor';
import ClubSelector from '../Selector';
import AdaptiveTab from 'components/Elements/AdaptiveTab';
import FeeTierScheduleSelector from '../../Tiers/Selector';
import NotificationGroupSelector from 'features/NotificationGroups/Selector';

const utilities = {
    formatCurrency: function (number, currencyCode = 'USD', locale = 'en-US') {
        return new Intl.NumberFormat(locale, { style: 'currency', currency: currencyCode }).format(number);
    },
    formatValue: function (value) {
        //should we look at union currency?
        return utilities.formatCurrency(value, 'USD', 'en-US');
    },
    emptyGuid: "00000000-0000-0000-0000-000000000000"
};

const TabPanel = function (props) {
    const { children, value, index, ...other } = props;
    return (
        <div role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}>
            {value === index && (
                <SoftBox >
                    <SoftTypography>{children}</SoftTypography>
                </SoftBox>
            )}
        </div>
    );
}


const ClubReferalSelector = ({ value, readonly, emptyValue, onSelectionChanged, onBuildListItem, maxHeight, referrals, clubs, reload }) => {


    const [availableOptions, setAvailableOptions] = useState(null);

    const [selectedValue, setSelectedValue] = useState(value ? value : emptyValue);
    const [selectedIndex, setSelectedIndex] = useState(null);

    const clubMap = [];

    function getSelectedIndex() {
        let dataSource = availableOptions; // getDataSource();

        if (!dataSource) return -1;
        if (!selectedValue) return -1;

        for (var i = 0; i < dataSource.length; i++) {
            if (dataSource[i].id == selectedValue.id || dataSource[i].id == selectedValue) {
                return i;
            }
        }

        return -1;
    }

    function findValueInDataSource(v) {
        let dataSource = getDataSource();

        if (!dataSource) return null;
        if (!v) return null;

        for (var i = 0; i < dataSource.length; i++) {
            if (dataSource[i].id == v.id || dataSource[i].id == v) {
                return dataSource[i];
            }
        }

        return -1;
    }

    function getDataSource() {
        if (!referrals) return null;

        if (clubMap.length === 0) {
            for (var i = 0; i < clubs.length; i++) {
                if (!clubMap[clubs[i].id]) {
                    clubMap[clubs[i].id] = clubs[i];
                }
            }
            for (var i = 0; i < referrals.length; i++) {
                referrals[i].referringClub = clubMap[referrals[i].referringClubId];
                referrals[i].referredClub = clubMap[referrals[i].referredClubId];
                referrals[i].id = referrals[i].referredClubId;
                referrals[i].name = referrals[i].referredClub ? referrals[i].referredClub.name : "Unknown";
            }
        }

        let rows = [...referrals];

        if (rows.length > 0) {
            rows.sort(function (a, b) {
                var aName = a.referredClub ? a.referredClub.name.toLowerCase() : "";
                var bName = b.referredClub ? b.referredClub.name.toLowerCase() : "";

                if (aName < bName) { return -1; }
                if (aName > bName) { return 1; }
                return 0;
            });
        }

        if (emptyValue) {
            rows.unshift(emptyValue);
        }

        return rows;
    }

    function handleScheduleChange({ item, index }) {
        setSelectedIndex(null);
        setSelectedValue(item);

        if (onSelectionChanged) {
            onSelectionChanged(item);
        }
    }


    useEffect(() => {
        if (clubs) {
            setAvailableOptions(getDataSource());

            if (selectedValue && !selectedIndex) {

                setSelectedIndex(getSelectedIndex());
            }
        }

    }, [clubs, selectedIndex, selectedValue]);

    useEffect(() => {
        if (clubs && value) {
            //find in datasource
            setSelectedValue(findValueInDataSource(value));
        }

    }, [clubs, value]);

    useEffect(() => {
        if (reload && referrals) {
            setAvailableOptions(getDataSource());
        }
    }, [referrals, reload]);

    return <>
        {readonly ?
            <SoftTypography
                variant="caption"
                fontWeight="regular"
                color="dark"
            > {selectedValue ? selectedValue.name : "[None]"}
            </SoftTypography> : <>{availableOptions ?

                <DataBoundList data={availableOptions} valueName="id" textName="name" onChange={handleScheduleChange}
                    selectedIndex={selectedIndex} onBuildListItem={onBuildListItem} maxHeight={maxHeight} />

                : null}
            </>
        }
    </>;
};

const ClubReferralEditorForm = ({ cardroom, union, club, config, formData, clubs }) => {

    const { formField, values, errors, touched, setFieldValue, setFieldError, setFieldTouched, validateForm, resetForm } = formData;
    const { clubId, feeSchedule, referralSchedule, referredClub } = formField;


    const [currentReferral, setCurrentReferral] = useState(null);
    const [availableClubs, setAvailableClubs] = useState(null);
    const [excludedClubs, setExcludedClubs] = useState(null);

    const [dataLoading, setDataLoading] = useState(false);

    const [selectedReferredClub, setSelectedReferredClub] = useState(null);
    const [selectedReferralSchedule, setSelectedReferralSchedule] = useState(null);
    const [reloadSelectorList, setReloadSelectorList] = useState(false);

    function buildListItem(item) {
        if (item.id) {
            if (item.id === "403") {
                return <>
                    <ListItemIcon style={{ minWidth: "32px" }}>
                        <Icon color={"error"}>block</Icon>
                    </ListItemIcon>
                    <ListItemText><SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                        textTransform="capitalize">
                        {item.name}
                    </SoftTypography></ListItemText></>;
            }
            else {
                return <ListItemText><SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize">
                    {item.name}
                </SoftTypography></ListItemText>;
            }
        }
        else {
            return <>
                <ListItemIcon style={{ minWidth: "32px" }}>
                    <Icon>add</Icon>
                </ListItemIcon>
                <ListItemText><SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize">
                    {item.name}
                </SoftTypography></ListItemText></>;
        }
    }

    function onScheduleSelectionChanged(referral) {
        if (referral && referral.id === "403") referral = null;

        setCurrentReferral(referral);

        /*  - handled inside useEffect logic watching currentReferral
        if (referral) {
            setSelectedReferredClub(referral.referredClub);
            setSelectedReferralSchedule(referral.referralSchedule);

            setFieldValue(referredClub.name, referral.referredClub ? referral.referredClub.id : "");
            setFieldValue(referralSchedule.name, referral.referralSchedule ? referral.referralSchedule.id : "");
        }
        */
    }

    function onReferredClubChanged(item) {
        if (item) {
            setSelectedReferredClub(item);
            setFieldValue(referredClub.name, item.id);
        }
    }
    function onReferralScheduleChanged(item) {
        if (item) {
            setSelectedReferralSchedule(item);
            setFieldValue(referralSchedule.name, item.id);
        }
    }

    function updateReferral() {
        if (!currentReferral) return;

        if (!canClubBeSaved()) {
            setFieldError(referredClub.name);
            setFieldTouched(referredClub.name);
        }

        if (canScheduleBeSaved()) {
            setFieldError(referralSchedule.name);
            setFieldTouched(referralSchedule.name);
        }
        if (errors.referredClub || errors.referralSchedule) return;


        let referral = currentReferral;
        let isNew = false;
        if (!referral.id) {
            referral = {
                id: selectedReferredClub.id,
                name: selectedReferredClub.name
            };
            isNew = true;
        }

        referral.referredClub = selectedReferredClub;
        referral.referringClub = club;

        referral.referringClubId = referral.referringClub.id;
        referral.referredClubId = referral.referredClub.id;

        referral.referralSchedule = selectedReferralSchedule;


        if (isNew) {
            config.referrals.push(referral);
        }

        setFieldValue(referredClub.name, "123");
        setFieldValue(referralSchedule.name, "123");

        setReloadSelectorList(true);
    }

    function getExcludedClubs() {
        const excludedList = [];

        excludedList.push(club);

        const currentlyReferredClub = currentReferral && currentReferral.referredClub ? currentReferral.referredClub : null;
        const currentlyReferredClubId = currentlyReferredClub ? currentlyReferredClub.id : null;

        if (config && config.referrals) {
            for (var i = 0; i < config.referrals.length; i++) {
                if (!config.referrals[i].referredClub) continue;
                if (config.referrals[i].referredClub && currentlyReferredClubId && currentlyReferredClubId == config.referrals[i].referredClub.id) continue;

                excludedList.push(config.referrals[i].referredClub);
            }
        }

        return excludedList;
    }

    function getClubsEligibleForSelection() {
        return clubs;
    }

    function canSave() {
        return canSetReferrals() && canClubBeSaved() && canScheduleBeSaved();
    }

    function canClubBeSaved() {
        if (!selectedReferredClub || !selectedReferredClub.id) {
            // setFieldError(referredClub.name);
            // setFieldTouched(referredClub.name);
            return false;
        }

        if (!config.referredClub && selectedReferredClub) return true;

        return config.referredClub.id != selectedReferredClub.id;

    }
    function canScheduleBeSaved() {
        if (!selectedReferralSchedule || !selectedReferralSchedule.id) {

            // setFieldError(referralSchedule.name);
            // setFieldTouched(referralSchedule.name);
            return false;
        }


        if (!config.referralSchedule && selectedReferralSchedule) return true;

        return config.referralSchedule.id != selectedReferralSchedule.id;
    }

    function canSetReferrals() {
        //if (!context) return false;

        // ReferralScheduleSet = 8
        if ((union.features & 8) === 0) return false;

        return true;
    }

    const emptyReferralValue = canSetReferrals() ? { id: null, name: "New Referral" } : { id: "403", name: "Managed by parent union" };

    useEffect(() => {
        if (reloadSelectorList) {
            setReloadSelectorList(false);
            setCurrentReferral(null);
        }

    }, [reloadSelectorList]);

    useEffect(() => {
        //setDataLoading(true);

        //if (currentReferral) {
        resetForm();

        //set defaults
        setFieldValue(clubId.name, club.id);
        setFieldValue(feeSchedule.name, config && config.feeSchedule ? config.feeSchedule.id : "");


        if (currentReferral) {
            if (currentReferral.id) {
                setSelectedReferredClub(currentReferral.referredClub);
                setSelectedReferralSchedule(currentReferral.referralSchedule);

                setFieldValue(referralSchedule.name, currentReferral.referralSchedule.id);
                setFieldValue(referredClub.name, currentReferral.referredClub.id);
            }
            else {
                setSelectedReferredClub(null);
                setSelectedReferralSchedule(null);

                setFieldValue(referralSchedule.name, "");
                setFieldValue(referredClub.name, "");
            }
        }
        else {
            setFieldValue(referralSchedule.name, "123");
            setFieldValue(referredClub.name, "123");
        }
        setAvailableClubs(getClubsEligibleForSelection());
        setExcludedClubs(getExcludedClubs());
        //}

    }, [currentReferral]);


    return <Grid width="100%" xs={12}>
        <Grid container xs={12}>
            <Grid item xs={4} pr={2} sx={{ height: 375 }}>
                <ClubReferalSelector referrals={config ? config.referrals : null} clubs={clubs} maxHeight={"375px"}
                    onBuildListItem={buildListItem} onSelectionChanged={onScheduleSelectionChanged} emptyValue={emptyReferralValue} reload={reloadSelectorList} />
            </Grid>
            <Grid item xs={8}>
                <Card style={{ overflow: "auto" }}>
                    <Card sx={{ height: 375 }}>
                        {currentReferral ?
                            <Grid container spacing={1} mt={2}>
                                <Grid item xs={12} sm={12} m={2}>
                                    <SoftBox mb={2} textAlign="left">
                                        <Grid container xs={12}>
                                            <Grid item xs={4} >
                                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                                    <SoftTypography
                                                        component="label"
                                                        variant="caption"
                                                        fontWeight="bold"
                                                        textTransform="capitalize">
                                                        {referredClub.label}
                                                    </SoftTypography>
                                                </SoftBox>
                                            </Grid>
                                            <Grid item xs={8}>
                                                {dataLoading ? <></> : <>
                                                    <ClubSelector placeholder={referredClub.placeholder} valueName="id" textName="name" clubs={availableClubs} excludedClubs={excludedClubs}
                                                        value={selectedReferredClub} onSelectionChanged={onReferredClubChanged} readonly={!canSetReferrals()} />
                                                    {errors.referredClub && touched.referredClub ?
                                                        <SoftTypography
                                                            component="label"
                                                            variant="caption"
                                                            color="error">
                                                            <ErrorMessage name={referredClub.name} />
                                                        </SoftTypography> : null}
                                                </>}
                                            </Grid>
                                        </Grid>

                                        <Grid container xs={12} mt={2}>
                                            <Grid item xs={4} >
                                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                                    <SoftTypography
                                                        component="label"
                                                        variant="caption"
                                                        fontWeight="bold"
                                                        textTransform="capitalize">
                                                        {referralSchedule.label}
                                                    </SoftTypography>
                                                </SoftBox>
                                            </Grid>
                                            <Grid item xs={8}>
                                                {dataLoading ? <></> : <>
                                                    <ReferralFeeSelector cardroom={cardroom} union={union} value={selectedReferralSchedule} emptyValue={{ id: "none", name: "None" }}
                                                        placeholder={referralSchedule.placeholder} onSelectionChanged={onReferralScheduleChanged} readonly={!canSetReferrals()} />
                                                    {errors.referralSchedule && touched.referralSchedule ?
                                                        <SoftTypography
                                                            component="label"
                                                            variant="caption"
                                                            color="error">
                                                            <ErrorMessage name={referralSchedule.name} />
                                                        </SoftTypography> : null}
                                                </>}
                                            </Grid>
                                        </Grid>

                                    </SoftBox>

                                    <Grid container xs={12} display={"flex"} flexDirection={"row-reverse"}>
                                        <SoftButton variant="outlined" color={canSave() ? "dark" : "secondary"} onClick={updateReferral} disabled={!canSave()}>
                                            OK
                                        </SoftButton>
                                    </Grid>
                                </Grid>
                            </Grid > :
                            <Grid container xs={12} justifyContent="center" alignItems="center">
                                <Grid item xs={12} textAlign={"center"}>

                                    <SoftTypography
                                        component="h5"
                                        variant="caption"
                                        color="info">
                                        Select referral from the list or tap "add new" to create a new referral
                                    </SoftTypography>

                                </Grid>
                            </Grid>
                        }</Card>
                </Card>
            </Grid>
        </Grid>
    </Grid>;
};

const ClubTierStepFeeScheduleContainer = ({ cardroom, union, club, step, index, feeScheduleId, readonly, onFeeScheduleChanged, feeScheduleFormField }) => {

    const [selectedFeeScheduleId, setSelectedFeeScheduleId] = useState(null);

    useEffect(() => {
        if (feeScheduleId && !selectedFeeScheduleId) {
            setSelectedFeeScheduleId(feeScheduleId);
        }
    }, [feeScheduleId, selectedFeeScheduleId])

    function stepFeeScheduleChanged(sched) {
        setSelectedFeeScheduleId(sched ? sched.id : null);

        if (onFeeScheduleChanged) onFeeScheduleChanged(step, sched);
    }

    function getStepDisplayName() {
        let msg = "Tier " + (index + 1) + ": ";
        if (step.max) {
            msg += "from " + utilities.formatValue(step.min) + " to " + utilities.formatValue(step.max);
        }
        else {
            msg += utilities.formatValue(step.min) + "+";
        }

        return msg;
    }

    return <Grid container xs={12} display="flex" justifyItems={"center"}>
        <Grid item xs={12} md={6}>
            <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                >
                    {getStepDisplayName()}
                </SoftTypography>
            </SoftBox>
        </Grid>
        <Grid item xs={12} md={6}>
            <ClubFeeSelector cardroom={cardroom} union={union} club={club} placeholder={feeScheduleFormField.placeholder}
                onSelectionChanged={stepFeeScheduleChanged} value={selectedFeeScheduleId}
                emptyValue={{ id: "", name: "[ Union Default ]" }} type="dropdown" readonly={readonly} />

            <SoftTypography
                component="label"
                variant="caption"
                color="error">
                <ErrorMessage name={feeScheduleFormField.name} />
            </SoftTypography>

        </Grid>
    </Grid>


}


const ClubEditorForm = ({ cardroom, union, club, config, clubs, formData, activeTab }) => {

    //const [tabValue, setTabValue] = useState(0);
    //const handleSetTabValue = (event, newValue) => setTabValue(newValue);

    const { formField, values, errors, touched, setFieldValue, setFieldError, setFieldTouched, validateForm, resetForm } = formData;
    const { clubId, feeSchedule, referralSchedule, referredClub, useTiers, feeTierId, notificationGroupId } = formField;

    const { clubId: clubIdV,
        feeSchedule: feeScheduleV,
        referralSchedule: referralScheduleV,
        referredClub: referredClubV,
        useTiers: useTiersV,
        feeTierId: feeTierIdV,
        notificationGroupId: notificationGroupIdV
    } = values;

    const [formDataInitialized, setFormDataInitialized] = useState(false);
    const [selectedFeeTierSchedule, setSelectedFeeTierSchedule] = useState(null);

    const [context, actions, features] = useCardroomContext(cardroom);

    function canEditClub() {
        if (!context) return false;

        return true;
    }

    function areTiersSupported() {
        return true;
    }

    function canSetFeeSchedule() {
        if (!context) return false;

        // ClubFeeScheduleSet = 2,
        if ((union.features & 2) === 0) return false;

        return true;
    }


    function onClubFeeSchedChanged(schedule) {
        setFieldValue(feeSchedule.name, schedule && schedule.id ? schedule.id : "");

        if (formDataInitialized) {
            config.feeScheduleChanged = true;
        }

        // if (schedule && schedule.id) {
        //     config.feeSchedule = schedule;
        // }
    }

    function onFeeTierSchedChanged(schedule) {

        setFieldValue(feeTierId.name, schedule && schedule.id ? schedule.id : "");

        setSelectedFeeTierSchedule(schedule);
    }

    function onNotifyGroupChanged(group) {
        setFieldValue(notificationGroupId.name, group && group.id ? group.id : "");
    }

    useEffect(() => {
        if (!formDataInitialized && club && config) {

            setFieldValue(clubId.name, club.id);

            setFieldValue(notificationGroupId.name, club.notificationGroupId && club.notificationGroupId != utilities.emptyGuid ? club.notificationGroupId : "");

            //need to refactor Club to carry club.feeSchedule && referralSchedule -- or at least have property w/ feeScheduleId & referralScheduleId

            var tiersUsed = Boolean(config && config.tierConfig && config.tierConfig.steps && config.tierConfig.steps.length > 1);

            setFieldValue(useTiers.name, tiersUsed);

            setFieldValue(feeTierId.name, config && config.tierConfig ? config.tierConfig.id : "");

            var feeSchedId = "";
            if (!tiersUsed && config && config.tierConfig && config.tierConfig.steps && config.tierConfig.steps.length > 0) feeSchedId = config.tierConfig.steps[0].scheduleId;

            setFieldValue(feeSchedule.name, feeSchedId);

            setFieldValue(referredClub.name, "123");
            setFieldValue(referralSchedule.name, "123");


            setFormDataInitialized(true);
        }
    }, [formDataInitialized, club, config]);


    function tierStepScheduleAssigned(step, schedule) {
        if (!config.tierConfig) {
            config.tierConfig = {
                //id: selectedFeeTierSchedule.id,
                //name: selectedFeeTierSchedule.name,
                steps: []
            }
        }
        else {
            if (!config.tierConfig.name && config.tierConfig.id == utilities.emptyGuid) {
                //reset steps for when we're going from single schedule to tier-based
                config.tierConfig.steps = [];
            }
        }


        config.tierConfig.id = selectedFeeTierSchedule.id;
        config.tierConfig.name = selectedFeeTierSchedule.name;

        var cfgStep = null;
        for (var i = 0; i < config.tierConfig.steps.length; i++) {
            if (config.tierConfig.steps[i].id === step.id) {
                cfgStep = config.tierConfig.steps[i];
                break;
            }
        }

        if (!cfgStep) {
            cfgStep = { id: step.id };
            config.tierConfig.steps.push(cfgStep);
        }

        cfgStep.scheduleId = schedule.id;

    }

    return <SoftBox>
        <Grid container xs={12} minHeight="400px">
            <Grid item xs={12} md={12} lg={12} sx={{ ml: "auto", mr: "auto" }} >
                <SoftBox>
                    <TabPanel value={activeTab} index={0}>
                        <Grid container spacing={1} mt={2}>
                            <Grid item xs={12} sm={4} container justifyContent="center">
                                <SoftBox position="relative" height="max-content" mx="auto">
                                    <SoftAvatar src={null} size="xxl" variant="rounded" bgColor="dark" />
                                </SoftBox>
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <SoftBox mb={2} textAlign="left">
                                    <Grid container xs={12}>
                                        <Grid item xs={4} >
                                            <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                                <SoftTypography
                                                    component="label"
                                                    variant="caption"
                                                    fontWeight="bold"
                                                    textTransform="capitalize">
                                                    {clubId.label}
                                                </SoftTypography>
                                            </SoftBox>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <SoftTypography pl={.5}
                                                component="label"
                                                variant="caption"
                                                fontWeight="bold"
                                                textTransform="capitalize">
                                                {club ? club.name : ""}
                                            </SoftTypography>
                                        </Grid>
                                    </Grid>

                                    {formDataInitialized ? <>

                                        <Grid container xs={12}>
                                            <Grid item xs={4} >
                                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                                    <SoftTypography
                                                        component="label"
                                                        variant="caption"
                                                        fontWeight="bold"
                                                        textTransform="capitalize">
                                                        {notificationGroupId.label}
                                                    </SoftTypography>
                                                </SoftBox>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <NotificationGroupSelector cardroom={cardroom} value={notificationGroupIdV} placeholder={notificationGroupId.placeholder}
                                                    emptyValue={{ id: "", name: "[ None ]" }} type="dropdown" onSelectionChanged={onNotifyGroupChanged} readonly={!canEditClub()} />

                                                <SoftTypography
                                                    component="label"
                                                    variant="caption"
                                                    color="error">
                                                    <ErrorMessage name={notificationGroupId.name} />
                                                </SoftTypography>

                                            </Grid>
                                        </Grid>

                                        {areTiersSupported() ?
                                            <Grid container xs={12}>
                                                <Grid item xs={4} >
                                                    <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                                        <SoftTypography
                                                            component="label"
                                                            variant="caption"
                                                            fontWeight="bold"
                                                            textTransform="capitalize">
                                                            {useTiers.label}
                                                        </SoftTypography>
                                                    </SoftBox>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <FormSwitch value={useTiersV}
                                                        name={useTiers.name}
                                                        variant="default"
                                                        readonly={!canEditClub() || !canSetFeeSchedule()}
                                                        {...formData} />
                                                </Grid>
                                            </Grid> : null}

                                        {useTiersV ? <>
                                            <Grid container xs={12}>
                                                <Grid item xs={4} >
                                                    <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                                        <SoftTypography
                                                            component="label"
                                                            variant="caption"
                                                            fontWeight="bold"
                                                            textTransform="capitalize">
                                                            {feeTierId.label}
                                                        </SoftTypography>
                                                    </SoftBox>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <FeeTierScheduleSelector cardroom={cardroom} union={union} value={feeTierIdV} placeholder={feeTierId.placeholder}
                                                        emptyValue={{ id: "", name: "[ None ]" }} type="dropdown" onSelectionChanged={onFeeTierSchedChanged} readonly={!canEditClub() || !canSetFeeSchedule()} />

                                                    <SoftTypography
                                                        component="label"
                                                        variant="caption"
                                                        color="error">
                                                        <ErrorMessage name={feeTierId.name} />
                                                    </SoftTypography>

                                                </Grid>
                                            </Grid>

                                            {feeTierIdV && selectedFeeTierSchedule ? selectedFeeTierSchedule.steps.map((s, idx) => {
                                                // need to set selected schedule for this step...

                                                var currentFeeScheduleId = null;
                                                if (config && config.tierConfig && config.tierConfig.steps) {
                                                    for (var i = 0; i < config.tierConfig.steps.length; i++) {
                                                        if (config.tierConfig.steps[i].id === s.id) {
                                                            currentFeeScheduleId = config.tierConfig.steps[i].scheduleId;
                                                            break;
                                                        }
                                                    }
                                                }
                                                //cardroom, union, club, step, index, readonly, onFeeScheduleChanged, feeScheduleFormField 
                                                return <ClubTierStepFeeScheduleContainer cardroom={cardroom} union={union} club={club} step={s} index={idx} feeScheduleId={currentFeeScheduleId}
                                                    readonly={!canEditClub() || !canSetFeeSchedule()} onFeeScheduleChanged={tierStepScheduleAssigned} feeScheduleFormField={feeSchedule} />

                                            }) : null}
                                        </> :

                                            <Grid container xs={12}>
                                                <Grid item xs={4} >
                                                    <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                                        <SoftTypography
                                                            component="label"
                                                            variant="caption"
                                                            fontWeight="bold"
                                                            textTransform="capitalize">
                                                            {feeSchedule.label}
                                                        </SoftTypography>
                                                    </SoftBox>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <ClubFeeSelector cardroom={cardroom} union={union} club={club} value={feeScheduleV} placeholder={feeSchedule.placeholder}
                                                        emptyValue={{ id: "", name: "[ Union Default ]" }} type="dropdown" onSelectionChanged={onClubFeeSchedChanged} readonly={!canEditClub() || !canSetFeeSchedule()} />

                                                    <SoftTypography
                                                        component="label"
                                                        variant="caption"
                                                        color="error">
                                                        <ErrorMessage name={feeSchedule.name} />
                                                    </SoftTypography>

                                                </Grid>
                                            </Grid>}
                                    </> : null}
                                </SoftBox>
                            </Grid>
                        </Grid >
                    </TabPanel>
                    <TabPanel value={activeTab} index={1}>
                        <Grid item xs={12}>
                            <SoftBox mb={2} textAlign="left">
                                <ClubReferralEditorForm cardroom={cardroom} club={club} union={union} config={config} clubs={clubs} formData={formData} />
                            </SoftBox>
                        </Grid>
                    </TabPanel>

                </SoftBox>

            </Grid>
        </Grid>
    </SoftBox >;
};

ClubEditorForm.propTypes = {
    cardoom: PropTypes.object.isRequired,
    club: PropTypes.object.isRequired,
    config: PropTypes.object.isRequired,
    union: PropTypes.object.isRequired
};

const InvitationSubEditor = ({ cardroom, club, union, onClose, activeTab }) => {

    return <SoftBox>

        <TabPanel value={activeTab} index={2}>
            <Grid item xs={12}>
                <SoftBox mb={2} textAlign="left">
                    <InvitationsEditor cardroom={cardroom} club={club} union={union} dense={true} />
                </SoftBox>
            </Grid>

        </TabPanel>


        <SoftBox mt={3} width="100%" display="flex" justifyContent="flex-end">

            <SoftButton variant="gradient" color="light" onClick={onClose}>
                close
            </SoftButton>
        </SoftBox>
    </SoftBox>

};

const ClubSubEditor = ({ cardroom, union, club, clubs, onClose, onClubUpdated, activeTab }) => {

    const { formId, formField } = form;
    const currentValidation = validations.editor;

    const [retryCounter, setRetryCounter] = useState(0);
    const [clubRecord, setClubRecord] = useState(null);
    const [clubConfig, setClubConfig] = useState(null);

    function handleRetry() {
        setRetryCounter(retryCounter + 1);
    }
    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "saving club...", handleRetry)

    const readRequest = useFetchWithMsal({
        scopes: apiConfig.club.scopes.read,
    });

    const writeRequest = useFetchWithMsal({
        scopes: apiConfig.club.scopes.write,
    });

    const [context, actions, features] = useCardroomContext(cardroom);

    function canEditClub() {
        if (!context) return false;

        return true;
    }

    const handleSubmit = (values, actions) => {

        if (false === canEditClub()) return;

        var cfg = {};
        if (clubConfig) {
            cfg = Object.assign(cfg, clubConfig);
        }

        cfg.notificationGroupId = values.notificationGroupId ? values.notificationGroupId : null;

        delete cfg.club;

        //   if (!values.feeSchedule) {
        //       cfg.feeSchedule = null;
        //   }

        //setClubRecord(profile);
        if (cfg.referrals) {
            const validReferrals = [];
            //remove club references
            for (var i = 0; i < cfg.referrals.length; i++) {
                if (!cfg.referrals[i]) continue;

                delete cfg.referrals[i].referringClub;
                delete cfg.referrals[i].referredClub;

                if (cfg.referrals[i].referralSchedule && cfg.referrals[i].referralSchedule.id && cfg.referrals[i].referralSchedule.id !== "none") {
                    validReferrals.push(cfg.referrals[i]);
                }
            }

            cfg.referrals = validReferrals;
        }

        if (!cfg.currency) {
            cfg.currency = club.currency || 'USD';
        }

        if ((cfg.feeScheduleChanged === true && !values.useTiers) || !values.feeSchedule) {
            //setup tiers as if there's no tiers??? -- maybe this should be done above

            cfg.tierConfig = {
                id: utilities.emptyGuid,
                name: "none",
                steps: [
                    {
                        id: utilities.emptyGuid,
                        scheduleId: values.feeSchedule ? values.feeSchedule : utilities.emptyGuid
                    }
                ]
            };

            delete cfg.feeScheduleChanged;
        }

        if (cfg.tierConfig && !cfg.tierConfig.name) {
            //occurs when we're just returning old config back
            cfg.tierConfig.name = "none";
        }

        setClubRecord(cfg);

    };


    useEffect(() => {
        if (clubRecord) {

            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Saving club...")
            showProgress();

            // [HttpPut("{unionId:Guid}/casino/{casinoId:Guid}/clubs/{clubId:Guid}/config", Name = "SetUnionClubConfig")]
            writeRequest.execute("PUT", apiConfig.club.endpoint + "/union/" + union.id + "/casino/" + cardroom.id + "/clubs/" + club.id + "/config", clubRecord).then((response) => {
                if (response) {
                    if (response.status && response.errors) {
                        throw new Error(response.errors);
                    }

                    setClubConfig(response);
                    raiseOnChanged(response);
                }

                setClubRecord(null);
                progressIndicatorProps.close();
                raiseOnClose();

            }).catch((e) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to save club, please try again...")
            });
        }

    }, [clubRecord, writeRequest.execute, retryCounter]);

    useEffect(() => {
        if (!clubConfig && union && club) {
            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Getting club configuration...")
            // [HttpGet("{unionId:Guid}/casino/{casinoId:Guid}/clubs/{clubId:Guid}/config", Name = "GetUnionClubConfig")]
            readRequest.execute("GET", apiConfig.club.endpoint + "/union/" + union.id + "/casino/" + cardroom.id + "/clubs/" + club.id + "/config").then((response) => {
                if (response) {
                    setClubConfig(response);
                }

                progressIndicatorProps.close();

            }).catch((e) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to get club configuration, please try again...")
            });
        }
    }, [readRequest.execute, clubConfig, union, club, retryCounter]);


    function raiseOnClose() {
        if (onClose) {
            onClose();
        }
    }

    function raiseOnChanged(cfg) {
        if (onClubUpdated) {
            onClubUpdated(cfg);
        }

        raiseOnClose();
    }

    return <Formik
        initialValues={initialValues}
        validationSchema={currentValidation}
        onSubmit={handleSubmit}
    >
        {({ values, errors, touched, setFieldValue, setFieldError, setFieldTouched, validateForm, resetForm, isSubmitting }) => {
            return <Form id={formId} autoComplete="off" style={{ marginTop: "-24px" }}>

                <SoftBox >
                    <ProgressIndicator {...progressIndicatorProps} />

                    {!progressIndicatorProps.visible && clubConfig ? <>
                        <ClubEditorForm cardroom={cardroom} club={club} union={union} config={clubConfig} clubs={clubs} activeTab={activeTab} formData={{
                            values,
                            touched,
                            formField,
                            errors,
                            setFieldValue,
                            setFieldError,
                            setFieldTouched,
                            validateForm,
                            resetForm
                        }} />

                        <SoftBox mt={3} width="100%" display="flex" justifyContent="space-between">

                            <SoftButton variant="gradient" color="light" onClick={raiseOnClose}>
                                cancel
                            </SoftButton>

                            <SoftButton
                                variant="gradient"
                                color="dark"
                                disabled={isSubmitting || !canEditClub()}
                                type="submit"
                            >
                                save
                            </SoftButton>
                        </SoftBox>
                    </> : <></>}

                </SoftBox>

            </Form>
        }}
    </Formik>;
};


const ClubEditor = ({ cardroom, union, club, clubs, onClose, onClubUpdated }) => {

    const tabs = [];

    tabs.push({
        title: { text: "Properties", icon: "contact_phone" },
        header: null,
        body: <ClubSubEditor cardroom={cardroom} union={union} club={club} clubs={clubs} onClubUpdated={onClubUpdated} onClose={onClose} activeTab={0} />
    });

    tabs.push({
        title: { text: "Referral Fees", icon: "recommend" },
        header: null,
        body: <ClubSubEditor cardroom={cardroom} union={union} club={club} clubs={clubs} onClubUpdated={onClubUpdated} onClose={onClose} activeTab={1} />
    });


    tabs.push({
        title: { text: "Invitations", icon: "person_add" },
        header: null,
        body: <InvitationSubEditor cardroom={cardroom} union={union} club={club} onClose={onClose} activeTab={2} />
    });

    return <SoftBox><AdaptiveTab switchToMobileOnSize="md" tabs={tabs} /></SoftBox>;
};



ClubEditor.propTypes = {
    cardoom: PropTypes.object.isRequired,
    club: PropTypes.object.isRequired,
    union: PropTypes.object.isRequired,
    onClubUpdated: PropTypes.func,
    onClose: PropTypes.func
};

export default ClubEditor;