
import React, { useState, useEffect, useLayoutEffect } from 'react';
import PropTypes from "prop-types";

import { apiConfig } from 'config/apiConfig'

import useFetchWithMsal from 'hooks/useFetchWithMsal';
import { ProgressIndicator, useProgressIndicator } from 'components/Elements/ProgressIndicator';
import { useCardroomContext } from 'features';
import { AppBar, Card, CardActions, CardContent, Divider, Grid, Icon, Paper, Switch, Tab, Table, TableBody, TableCell, TableHead, TableRow, Tabs, Tooltip, styled, useMediaQuery, useTheme } from '@mui/material';
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import defaultCasino from "assets/graphics/logos/default-casino.png";
import MoneyCell from 'components/Elements/DataTable/components/Cells/MoneyCell';
import Stack from '@mui/material/Stack';
import TextCell from 'components/Elements/DataTable/components/Cells/TextCell';
import DataTable from 'components/Elements/DataTable';
import ButtonCell from 'components/Elements/DataTable/components/Cells/ButtonCell';
import ReportUtilities from 'layouts/Club/Report/components/Navigation';
import SoftButton from 'components/SoftButton';
import { useDialog } from 'components/Elements/Dialog/common';
import ReportingPeriodPaymentsMapDialog from '../PaymentsMap';
import MiniStatisticsCard from 'components/Elements/MiniStatisticsCard';
import whiteCurved from "assets/images/curved-images/white-curved.jpeg";
import MiniChartItemCard from 'components/Elements/MiniChartItemCard';
import MiniActionCard from 'components/Elements/MiniActionCard';
import { useUnionCalculator } from '../common/calculator';
import AdaptiveTab from 'components/Elements/AdaptiveTab';
import AdhocLine, { AdhocLineDialog } from '../AdhocLine';
import { useUnionDataLoader } from 'layouts/Club/Union/components/common';


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    boxShadow: "none"
}));

const enableCache = true;

const utilities = {
    getValueInActiveCurrency: function (v, calculator, currency, currencyConverter) {
        if (!v) return calculator.zero();

        var v = currencyConverter.convertFromUSD(v, currency);

        return calculator.formatDecimalAsCurrency(v, currency.precision);
    }
};


const ReportUnionClubs = ({ cardroom, union, reportingPeriod, currency, currencyConverter }) => {

    const calculator = useUnionCalculator();

    function prepareForPresentation(clubs, unions, activeOnly, table) {
        let rows = [];
        if (!clubs) return null;

        var map = [];
        for (var i = 0; i < unions.length; i++) {
            if (!map[unions[i].id]) {
                map[unions[i].id] = unions[i];
            }
        }

        /*
                function calcEarnings(clubSummary) {
                    if (!clubSummary) return 0;
                    return (clubSummary.winnings + clubSummary.fees);
                }
                function calcUnionDues(clubSummary) {
                    if (!clubSummary) return 0;
                    return (clubSummary.fees * clubSummary.unionDuesRate * -1) - clubSummary.unionDuesBase;
                }
                function calcXMTTDues(clubSummary) {
                    if (!clubSummary) return 0;
                    return (clubSummary.xmttFees * clubSummary.xmttDuesRate * -1);
                }
                function calcChipOffset(clubSummary) {
                    if (!clubSummary) return 0;
                    return (calcEarnings(clubSummary) * clubSummary.chipOffsetRate * -1);
                }
                function calcTaxRebate(clubSummary) {
                    if (!clubSummary) return 0;
        
                    let taxableAmount = calcEarnings(clubSummary) - clubSummary.xmttPnL + clubSummary.jackpotPnL;
                    let winnerTax = taxableAmount > 0 ? taxableAmount * clubSummary.winTaxRate : 0;
                    return (taxableAmount * clubSummary.taxRebateRate) - winnerTax;
                }
        
        */

        for (var i = 0; i < clubs.length; i++) {
            //if (activeOnly && clubs[i].settlementAmount == 0 && clubs[i].fees == 0 && clubs[i].winnings == 0 && clubs[i].referralBonus == 0) continue;
            if (activeOnly && clubs[i].playerCount === 0) continue;

            if (union.isVirtual && clubs[i].parentUnionId != union.id) continue;

            var u = map[clubs[i].parentUnionId];

            rows.push({
                clubName: clubs[i].club ? clubs[i].club.name : "",
                unionName: u ? u.name : "",
                club: clubs[i],
                union: u,
                wins: clubs[i].winnings,
                fees: clubs[i].fees,
                unionDues: calculator.unionDues(clubs[i]),
                xmttDues: calculator.xmttDues(clubs[i]),
                chips: calculator.chipOffset(clubs[i]),
                tax: calculator.ecoTax(clubs[i]), //-1 * calcTaxRebate(clubs[i]),
                referrals: clubs[i].referralBonus,
                dividends: clubs[i].loyaltyRebate,
                settlement: clubs[i].settlementAmount, //should prob calc here
                summary: clubs[i]
            });
        }

        if (rows.length) {
            rows.sort(function (a, b) {
                if (a.clubName.toLowerCase() < b.clubName.toLowerCase()) { return -1; }
                if (a.clubName.toLowerCase() > b.clubName.toLowerCase()) { return 1; }

                return 0;
            });
        }

        table.rows = rows;

        return table;
    }

    function getTableSchema(clubDetailButtonClicked) {
        return {
            columns: [
                { Header: "Club", accessor: "clubName", Cell: ({ value }) => <TextCell value={value} /> },
                { Header: "Winnings", accessor: "wins", Cell: ({ value }) => <MoneyCell value={getValueInActiveCurrency(value)} prefix={currencySymbol} useColorScheme={true} /> },
                { Header: "Fees", accessor: "fees", Cell: ({ value }) => <MoneyCell value={getValueInActiveCurrency(value)} prefix={currencySymbol} useColorScheme={true} /> },
                { Header: "Union Dues", accessor: "unionDues", Cell: ({ value }) => <MoneyCell value={getValueInActiveCurrency(value)} prefix={currencySymbol} useColorScheme={true} /> },
                { Header: "XMTT Dues", accessor: "xmttDues", Cell: ({ value }) => <MoneyCell value={getValueInActiveCurrency(value)} prefix={currencySymbol} useColorScheme={true} /> },
                { Header: "Chip Offset", accessor: "chips", Cell: ({ value }) => <MoneyCell value={getValueInActiveCurrency(value)} prefix={currencySymbol} useColorScheme={true} /> },
                { Header: "Tax Rebate", accessor: "tax", Cell: ({ value }) => <MoneyCell value={getValueInActiveCurrency(value)} prefix={currencySymbol} useColorScheme={true} /> },
                { Header: "Referrals", accessor: "referrals", Cell: ({ value }) => <MoneyCell value={getValueInActiveCurrency(value)} prefix={currencySymbol} useColorScheme={true} /> },
                { Header: "Dividend", accessor: "dividends", Cell: ({ value }) => <MoneyCell value={getValueInActiveCurrency(value)} prefix={currencySymbol} useColorScheme={true} /> },
                { Header: "Settlement", accessor: "settlement", Cell: ({ value }) => <MoneyCell value={getValueInActiveCurrency(value)} prefix={currencySymbol} useColorScheme={true} /> },
                { Header: "Union", accessor: "unionName", Cell: ({ value }) => <TextCell value={value} /> },
                { Header: "details", accessor: "summary", Cell: ({ value }) => <ButtonCell id={value} onButtonClicked={clubDetailButtonClicked} icon="launch" color="secondary" /> },
            ],
            rows: []
        };
    }

    function getValueInActiveCurrency(v) {
        return utilities.getValueInActiveCurrency(v, unionCalculator, currency, currencyConverter);
    }

    const [currencySymbol, setCurrencySymbol] = useState(null);

    const [context, actions, features] = useCardroomContext(cardroom);
    function isCurrencyMaskingEnabled() {
        if (!context) return false;
        return context.isEnabled(features.currency.mask, 0);
    }

    useEffect(() => {
        if (true === isCurrencyMaskingEnabled()) {
            setCurrencySymbol("");
            return;
        }

        if (currency) setCurrencySymbol(currency.symbol);
    }, [currency, currencyConverter]);


    const switchToMobileOnSize = "md";

    const theme = useTheme();
    const isMobileUi = !useMediaQuery(theme.breakpoints.up(switchToMobileOnSize));
    const isXs = !useMediaQuery(theme.breakpoints.up("sm"));

    function onClubReportClicked(summary) {
        if (!summary) return;

        let c = summary.club;
        c.app = reportingPeriod.app;

        ReportUtilities.publish("/dashboards/club/report", { cardroom: cardroom, union: union, club: c, report: reportingPeriod });
    }

    const [clubsTable, setClubsTable] = useState(null);
    const [clubs, setClubs] = useState(null);
    const [unions, setUnions] = useState(null);

    const [retryCounter, setRetryCounter] = useState(0);
    function handleProgressRetry() {
        setRetryCounter(retryCounter + 1);
    }

    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "Loading clubs...", handleProgressRetry);
    const clubRequest = useFetchWithMsal({
        scopes: apiConfig.club.scopes.read,
    });

    const dataLoader = useUnionDataLoader();

    useEffect(() => {
        if (!clubs) {

            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading club details...")
            showProgress();

            dataLoader.report.loadClubs(clubRequest, cardroom, reportingPeriod, union, enableCache, (clubs) => {
                //success
                if (clubs) {
                    setClubs(clubs);
                    setClubsTable(null);
                }
            }, (ex) => {
                //error
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load club details, plese try again...");
            });
        }
    }, [clubRequest.execute, clubs, retryCounter]);

    useEffect(() => {

        if (!unions && clubs) {

            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading unions...")
            showProgress();

            dataLoader.report.loadUnions(clubRequest, cardroom, union, enableCache, (unions) => {
                //success
                if (unions) {
                    setUnions(unions);
                }
            }, (ex) => {
                //error
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load unions, plese try again...");
            });
        }

    }, [clubRequest.execute, clubs, unions, retryCounter]);

    useEffect(() => {
        if (clubs && unions && !clubsTable) {
            //load tables
            setClubsTable(prepareForPresentation(clubs, [...unions, union], activeClubsOnly, getTableSchema(onClubReportClicked)));
            progressIndicatorProps.close();
        }

    }, [clubs, unions, clubsTable]);

    const [activeClubsOnly, setActiveClubsOnly] = useState(true);
    const [tableRefreshNeeded, setTableRefreshNeeded] = useState(false);

    function onActiveClubsOnlySwitchToggle() {
        let newValue = !activeClubsOnly;
        setActiveClubsOnly(newValue);
        setTableRefreshNeeded(true);
    }

    useLayoutEffect(() => {
        if (clubs && clubsTable && tableRefreshNeeded) {
            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Filtering clubs...")
            showProgress();

            setClubsTable(null);
            setTableRefreshNeeded(false);
        }
    }, [activeClubsOnly, tableRefreshNeeded, clubs, clubsTable])

    const unionCalculator = useUnionCalculator();

    function renderMobileRow(columns, row, key, collapsibleDetailAccessor) {
        const color = "secondary";
        const club = row.original.club.club;



        function getMoneyColor(v) {
            if (v > 0) return "success";

            if (v < 0) return "error";

            return "secondary";
        }

        return <Card
            sx={({ functions: { linearGradient, rgba }, palette: { gradients } }) => ({
                backgroundImage: gradients[color]
                    ? `${linearGradient(
                        rgba(gradients[color].main, 0.85),
                        rgba(gradients[color].state, 0.85)
                    )}, url(${whiteCurved})`
                    : `${linearGradient(
                        rgba(gradients[color].main, 0.85),
                        rgba(gradients[color].state, 0.85)
                    )}, url(${whiteCurved})`,
                backgroundSize: "cover",
                backgroundPosition: "50%",
                overflow: "visible",
            })}
        >
            <CardContent>
                <SoftBox pt={1} textAlign="center">
                    <MiniStatisticsCard
                        bgColor="white"
                        title={{ text: club.appClubId, fontWeight: "medium" }}
                        count={club.name}
                        icon={{ color: "dark", component: "nightlife" }}
                        direction={"left"}
                        useNumericFormatter={false}
                    />
                </SoftBox>

                <SoftBox pt={1} textAlign="center">
                    <MiniActionCard
                        bgColor="white"
                        title={{ text: "settlement", fontWeight: "medium" }}
                        count={{ text: unionCalculator.formatDecimalAsCurrency(row.original.settlement), color: getMoneyColor(row.original.settlement), useNumberFormatter: true, prefix: currencySymbol }}
                        icon={{ color: "secondary", component: "payment" }}
                        direction="left" />
                </SoftBox>

                <SoftBox pt={1} textAlign="center">
                    <Grid container ml="auto" mr="auto" xs={12}>
                        <Grid item xs={6} pr={0.25} pt={{ xs: 1, sm: 0 }} pb={{ xs: 1, sm: 0 }}>
                            <MiniActionCard
                                bgColor="white"
                                title={{ text: "winnings", fontWeight: "medium" }}
                                count={{ text: unionCalculator.formatDecimalAsCurrency(row.original.wins), color: getMoneyColor(row.original.wins), useNumberFormatter: true, prefix: currencySymbol }}
                                icon={isXs ? null : { color: "secondary", component: "emoji_events" }}
                                direction="left"
                            />
                        </Grid>
                        <Grid item xs={6} pl={0.25} pr={{ xs: 0.25, sm: 0 }} pt={{ xs: 1, sm: 0 }} pb={{ xs: 1, sm: 0 }}>
                            <MiniActionCard
                                bgColor="white"
                                title={{ text: "fees", fontWeight: "medium" }}
                                count={{ text: unionCalculator.formatDecimalAsCurrency(row.original.fees), color: "success", useNumberFormatter: true, prefix: currencySymbol }}
                                icon={isXs ? null : { color: "secondary", component: "percent" }}
                            />
                        </Grid>
                    </Grid>
                </SoftBox>

                <SoftBox pt={1} textAlign="center">
                    <Grid container ml="auto" mr="auto" xs={12}>
                        <Grid item xs={6} pr={0.25} pt={{ xs: 1, sm: 0 }} pb={{ xs: 1, sm: 0 }}>
                            <MiniActionCard
                                bgColor="white"
                                title={{ text: "union dues", fontWeight: "medium" }}
                                count={{ text: unionCalculator.formatDecimalAsCurrency(row.original.unionDues), color: getMoneyColor(row.original.unionDues), useNumberFormatter: true, prefix: currencySymbol }}
                                icon={isXs ? null : { color: "secondary", component: "diversity_3" }}
                                direction="left"
                            />
                        </Grid>
                        <Grid item xs={6} pl={0.25} pr={{ xs: 0.25, sm: 0 }} pt={{ xs: 1, sm: 0 }} pb={{ xs: 1, sm: 0 }}>
                            <MiniActionCard
                                bgColor="white"
                                title={{ text: "xmtt dues", fontWeight: "medium" }}
                                count={{
                                    text: unionCalculator.formatDecimalAsCurrency(row.original.xmttDues), color: getMoneyColor(row.original.xmttDues), useNumberFormatter: true, prefix: currencySymbol
                                }}
                                icon={isXs ? null : { color: "secondary", component: "diversity_1" }}
                            />
                        </Grid>
                    </Grid>
                </SoftBox>

                <SoftBox pt={1} textAlign="center">
                    <Grid container ml="auto" mr="auto" xs={12}>
                        <Grid item xs={6} pr={0.25} pt={{ xs: 1, sm: 0 }} pb={{ xs: 1, sm: 0 }}>
                            <MiniActionCard
                                bgColor="white"
                                title={{ text: "chip offset", fontWeight: "medium" }}
                                count={{ text: unionCalculator.formatDecimalAsCurrency(row.original.chips), color: getMoneyColor(row.original.chips), useNumberFormatter: true, prefix: currencySymbol }}
                                icon={isXs ? null : { color: "secondary", component: "calculate" }}
                                direction="left"
                            />
                        </Grid>
                        <Grid item xs={6} pl={0.25} pr={{ xs: 0.25, sm: 0 }} pt={{ xs: 1, sm: 0 }} pb={{ xs: 1, sm: 0 }}>
                            <MiniActionCard
                                bgColor="white"
                                title={{ text: "tax / rebate", fontWeight: "medium" }}
                                count={{ text: unionCalculator.formatDecimalAsCurrency(row.original.tax), color: getMoneyColor(row.original.tax), useNumberFormatter: true, prefix: currencySymbol }}
                                icon={isXs ? null : { color: "secondary", component: "account_balance" }}
                            />
                        </Grid>
                    </Grid>
                </SoftBox>

                <SoftBox pt={1} textAlign="center">
                    <Grid container ml="auto" mr="auto" xs={12}>
                        <Grid item xs={6} pr={0.25} pt={{ xs: 1, sm: 0 }} pb={{ xs: 1, sm: 0 }}>
                            <MiniActionCard
                                bgColor="white"
                                title={{ text: "divident", fontWeight: "medium" }}
                                count={{ text: unionCalculator.formatDecimalAsCurrency(row.original.dividents), color: getMoneyColor(row.original.dividents), useNumberFormatter: true, prefix: currencySymbol }}
                                icon={isXs ? null : { color: "secondary", component: "monetization_on" }}
                                direction="left"
                            />
                        </Grid>
                        <Grid item xs={6} pl={0.25} pr={{ xs: 0.25, sm: 0 }} pt={{ xs: 1, sm: 0 }} pb={{ xs: 1, sm: 0 }}>
                            <MiniActionCard
                                bgColor="white"
                                title={{ text: "referrals", fontWeight: "medium" }}
                                count={{ text: unionCalculator.formatDecimalAsCurrency(row.original.referrals), color: getMoneyColor(row.original.referrals), useNumberFormatter: true, prefix: currencySymbol }}
                                icon={isXs ? null : { color: "secondary", component: "savings" }}
                            />
                        </Grid>
                    </Grid>
                </SoftBox>

                <SoftBox pt={1} textAlign="center">
                    <MiniActionCard
                        bgColor="white"
                        title={{ text: row.original.union.appUnionId, fontWeight: "medium" }}
                        count={{ text: row.original.union.name, color: "dark", useNumberFormatter: false, prefix: null }}
                        icon={{ color: "dark", component: "beach_access" }}
                        direction={"right"}
                    />
                </SoftBox>
            </CardContent>
            <CardActions>
                <Grid container xs={12}>
                    <Grid item xs={12} pl={1.5} pr={1.5}>
                        <SoftButton
                            variant="gradient"
                            color="dark"
                            fullWidth
                            onClick={() => onClubReportClicked(row.original.summary)}
                        >
                            OPEN&nbsp;
                            <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
                        </SoftButton>
                    </Grid>
                </Grid>

            </CardActions>
        </Card >;

    }

    const filter = <Grid container xs={12} justifyContent="center" ml={5}>
        <Grid item>
            <SoftBox mt={0.25} >
                <Switch checked={activeClubsOnly} onChange={onActiveClubsOnlySwitchToggle} />
            </SoftBox>
        </Grid>
        <Grid item>
            <SoftBox ml={1} display="inlineBox">
                <SoftTypography component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize">
                    Show only active clubs
                </SoftTypography>
            </SoftBox></Grid>
    </Grid >;

    return (<Card p={1}>
        <ProgressIndicator {...progressIndicatorProps} />
        {clubsTable && !progressIndicatorProps.visible ? <DataTable table={clubsTable} entriesPerPage={{ defaultValue: 10, visible: !isMobileUi }}
            canSearch showTotalEntries={true} isHierarchical={false} widget={filter}
            onRenderRow={renderMobileRow} useCustomRenderSize={switchToMobileOnSize} /> : null}
    </Card>);
};

//TODO: REMOVE
function formatDecimalValue(v) {
    if (!v) return 0;

    return v.toFixed(2);
}


ReportUnionClubs.propTypes = {
    cardroom: PropTypes.object.isRequired,
    club: PropTypes.object.isRequired,
    reportingPeriod: PropTypes.object.isRequired
};

const ReportSummaryCard = ({ cardroom, union, reportingPeriod, summary, legend, calculator, currency, currencySymbol, currencyConverter, onLineEdit, onLineDelete, showCostBreakDown }) => {

    function getValueInActiveCurrency(v) {
        return utilities.getValueInActiveCurrency(v, calculator, currency, currencyConverter);
    }

    return legend ? <Item style={{ maxWidth: "175px", backgroundColor: 'transparent', shadowOpacity: 0 }}>
        <Grid container xs={12} >
            <Grid xs={12}>&nbsp;</Grid>

            <Grid xs={12} style={{ whiteSpace: "nowrap", borderTop: "1px solid black" }} textAlign="left" >
                <Tooltip title="Amount won or lost by all clubs in the union" placement="top">
                    <SoftTypography variant="caption" color="secondary" fontWeight="medium" textTransform="uppercase">
                        Winnings
                    </SoftTypography>
                </Tooltip>
            </Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap" }} textAlign="left">
                <Tooltip title="Amount of fees generated by all clubs in the union" placement="top">
                    <SoftTypography variant="caption" color="secondary" fontWeight="medium" textTransform="uppercase">
                        Fee
                    </SoftTypography>
                </Tooltip>
            </Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap" }} textAlign="left" >
                <Tooltip title="Amount earned (winnings + fees) by all clubs in the union" placement="top">
                    <SoftTypography variant="caption" color="secondary" fontWeight="bold" textTransform="uppercase">
                        Earnings
                    </SoftTypography>
                </Tooltip>
            </Grid>

            <Grid xs={12} style={{ whiteSpace: "nowrap" }} textAlign="left">
                <Tooltip title="Portion of all fees generated by all clubs in the union" placement="top">
                    <SoftTypography variant="caption" color="secondary" fontWeight="medium" textTransform="uppercase">
                        Proportion
                    </SoftTypography>
                </Tooltip>
            </Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap" }} textAlign="left">
                <Tooltip title="Number of clubs in the union that reported activity this reporting period" placement="top">
                    <SoftTypography variant="caption" color="secondary" fontWeight="medium" textTransform="uppercase">
                        Active Clubs
                    </SoftTypography>
                </Tooltip>
            </Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap", borderBottom: "1px solid #17c1e8", borderTop: "1px solid #17c1e8" }} textAlign="left">
                <SoftTypography variant="caption" color="info" fontWeight="medium" textTransform="uppercase">
                    Expenses
                </SoftTypography>
            </Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap" }} textAlign="left">
                <Tooltip title={"Number of " + (reportingPeriod && reportingPeriod.app ? reportingPeriod.app.tokenName : "token").toLowerCase() + "s used by all clubs in the union"} placement="top">
                    <SoftTypography variant="caption" color="secondary" fontWeight="medium" textTransform="uppercase">
                        {reportingPeriod && reportingPeriod.app ? reportingPeriod.app.tokenName : "Token"}s Used
                    </SoftTypography>
                </Tooltip>
            </Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap" }} textAlign="left">
                <Tooltip title={"Cost of " + (reportingPeriod && reportingPeriod.app ? reportingPeriod.app.tokenName : "token").toLowerCase() + "s used by all clubs in the union"} placement="top">
                    <SoftTypography variant="caption" color="secondary" fontWeight="medium" textTransform="uppercase">
                        {reportingPeriod && reportingPeriod.app ? reportingPeriod.app.tokenName : "Token"}s Cost
                    </SoftTypography>
                </Tooltip>
            </Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap" }} textAlign="left">
                <Tooltip title="Gap in MTT guarantees incurred during this reporting period" placement="top">
                    <SoftTypography variant="caption" color="secondary" fontWeight="medium" textTransform="uppercase">
                        MTT Overlay
                    </SoftTypography>
                </Tooltip>
            </Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap" }} textAlign="left">
                <Tooltip title="Dues owed based on XMTT Earnings (XMTT Dues Rate * XMTT Earnings)" placement="top">
                    <SoftTypography variant="caption" color="secondary" fontWeight="medium" textTransform="uppercase">
                        XMTT Dues
                    </SoftTypography>
                </Tooltip>
            </Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap" }} textAlign="left">
                <Tooltip title="Union manager fees incurred during this reporting period" placement="top">
                    <SoftTypography variant="caption" color="secondary" fontWeight="medium" textTransform="uppercase">
                        Union Manager
                    </SoftTypography>
                </Tooltip>
            </Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap" }} textAlign="left">
                <Tooltip title="Data reporting costs incurred during this reporting period" placement="top">
                    <SoftTypography variant="caption" color="secondary" fontWeight="medium" textTransform="uppercase">
                        Reporting {showCostBreakDown ? " Total" : ""}
                    </SoftTypography>
                </Tooltip>
            </Grid>
            {showCostBreakDown ? <>
                <Grid xs={12} style={{ whiteSpace: "nowrap" }} textAlign="left">
                    <Tooltip title="Data reporting costs to be collected from clubs the union is responsible for" placement="top">
                        <SoftTypography variant="caption" color="secondary" fontWeight="medium" textTransform="uppercase">
                            Reporting Recovery
                        </SoftTypography>
                    </Tooltip>
                </Grid>
                <Grid xs={12} style={{ whiteSpace: "nowrap" }} textAlign="left">
                    <Tooltip title="Data reporting costs rebate resulting from clubs union is responsible for" placement="top">
                        <SoftTypography variant="caption" color="secondary" fontWeight="medium" textTransform="uppercase">
                            Reporting Rebate
                        </SoftTypography>
                    </Tooltip>
                </Grid>

            </> : null}

            {summary.adhoc && summary.adhoc.expenses ? <>
                {summary.adhoc.expenses.map((expense) => {

                    //shouldn't need as it should've been taken care of... could be good thing to leave to ensure nothing falls through?
                    // if (expense.ownedByReportId !== reportingPeriod.id && expense.splitWithSubEntity !== true) {
                    //     return null;
                    // }

                    return <Grid xs={12} style={{ whiteSpace: "nowrap" }} textAlign="left">
                        <AdhocLine cardroom={cardroom} union={union} reportingPeriod={reportingPeriod} line={expense} onLineEdit={onLineEdit} onLineDelete={onLineDelete} />
                    </Grid>
                })}
            </> : null}

            <Grid xs={12} style={{ whiteSpace: "nowrap" }} textAlign="left">
                <Tooltip title={"Incurred expenses (" + (reportingPeriod && reportingPeriod.app ? reportingPeriod.app.tokenName : "token").toLowerCase() + " costs + MTT Overlay + XMTT Dues + Management & Reporting costs)"} placement="top">
                    <SoftTypography variant="caption" color="secondary" fontWeight="bold" textTransform="uppercase">
                        Total Expenses
                    </SoftTypography>
                </Tooltip>
            </Grid>


            {summary.promos && summary.promos.length > 0 ? <>
                <Grid xs={12} style={{ whiteSpace: "nowrap", borderBottom: "1px solid #17c1e8", borderTop: "1px solid #17c1e8" }} textAlign="left">
                    <SoftTypography variant="caption" color="info" fontWeight="medium" textTransform="uppercase">
                        Promotions
                    </SoftTypography>
                </Grid>


                {summary.promos.map((line) => {

                    return <Grid xs={12} style={{ whiteSpace: "nowrap" }} textAlign="left">
                        <Tooltip title="Sum of all of club settlements in the union" placement="top">
                            <SoftTypography variant="caption" color="secondary" fontWeight="medium" textTransform="uppercase">
                                {line.name}
                            </SoftTypography>
                        </Tooltip>
                    </Grid>
                })}
            </> : null}


            <Grid xs={12} style={{ whiteSpace: "nowrap", borderBottom: "1px solid #17c1e8", borderTop: "1px solid #17c1e8" }} textAlign="left">
                <SoftTypography variant="caption" color="info" fontWeight="medium" textTransform="uppercase">
                    Profit Calcs
                </SoftTypography>
            </Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap" }} textAlign="left">
                <Tooltip title="Sum of all of club settlements in the union" placement="top">
                    <SoftTypography variant="caption" color="secondary" fontWeight="medium" textTransform="uppercase">
                        Club Settlement
                    </SoftTypography>
                </Tooltip>
            </Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap" }} textAlign="left">
                <Tooltip title="Sum of all of club dues owed to the union" placement="top">
                    <SoftTypography variant="caption" color="secondary" fontWeight="medium" textTransform="uppercase">
                        Club Union Dues
                    </SoftTypography>
                </Tooltip>
            </Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap" }} textAlign="left">
                <SoftTypography variant="caption" color="secondary" fontWeight="medium" textTransform="uppercase">
                    Total Expenses
                </SoftTypography>
            </Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap" }} textAlign="left">
                <Tooltip title="Amount owed as club referral fees" placement="top">
                    <SoftTypography variant="caption" color="secondary" fontWeight="medium" textTransform="uppercase">
                        Referrals Paid
                    </SoftTypography>
                </Tooltip>
            </Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap" }} textAlign="left">
                <Tooltip title="Amount paid in loyalty dividends" placement="top">
                    <SoftTypography variant="caption" color="secondary" fontWeight="medium" textTransform="uppercase">
                        Loyalty Dividends
                    </SoftTypography>
                </Tooltip>
            </Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap" }} textAlign="left">
                <Tooltip title="Total profit generated by the union (Union Settlements - all club settlements)" placement="top">
                    <SoftTypography variant="caption" color="secondary" fontWeight="medium" textTransform="uppercase">
                        Total Profit
                    </SoftTypography>
                </Tooltip>
            </Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap", borderBottom: "1px solid #17c1e8", borderTop: "1px solid #17c1e8" }} textAlign="left">
                <SoftTypography variant="caption" color="info" fontWeight="medium" textTransform="uppercase">
                    Settlement Calcs
                </SoftTypography>
            </Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap" }} textAlign="left">
                <SoftTypography variant="caption" color="secondary" fontWeight="medium" textTransform="uppercase">
                    Total Earnings
                </SoftTypography>
            </Grid>
            {/**
            <Grid xs={12} style={{ whiteSpace: "nowrap" }} textAlign="left">
                <SoftTypography variant="caption" color="secondary" fontWeight="medium" textTransform="uppercase">
                    Jackpot P&L
                </SoftTypography>
            </Grid>
           
            <Grid xs={12} style={{ whiteSpace: "nowrap" }} textAlign="left">
                <SoftTypography variant="caption" color="secondary" fontWeight="medium" textTransform="uppercase">
                    Spin'N'Go Offset
                </SoftTypography>
            </Grid>
            
            <Grid xs={12} style={{ whiteSpace: "nowrap" }} textAlign="left">
                <SoftTypography variant="caption" color="secondary" fontWeight="medium" textTransform="uppercase">
                    XMTT Dues
                </SoftTypography>
            </Grid> */}
            <Grid xs={12} style={{ whiteSpace: "nowrap" }} textAlign="left">
                <SoftTypography variant="caption" color="secondary" fontWeight="medium" textTransform="uppercase">
                    Chip Offset
                </SoftTypography>
            </Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap" }} textAlign="left">
                <SoftTypography variant="caption" color="secondary" fontWeight="medium" textTransform="uppercase">
                    Tax Rebate
                </SoftTypography>
            </Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap" }} textAlign="left">
                <SoftTypography variant="caption" color="secondary" fontWeight="medium" textTransform="uppercase">
                    Expenses
                </SoftTypography>
            </Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap", borderTop: "1px solid black" }} textAlign="left">
                <Tooltip title="Total earnings + XMTT Dues + Chip Offset + Tax Rebate + Expenses" placement="top">
                    <SoftTypography variant="caption" color="error" fontWeight="bold" textTransform="uppercase">
                        Net Settlement
                    </SoftTypography>
                </Tooltip>
            </Grid>
        </Grid>
    </Item> : <Item style={{ minWidth: "125px", backgroundColor: 'transparent', shadowOpacity: 0 }}>
        <Grid container xs={12} >
            <Grid xs={12} style={{ whiteSpace: "nowrap" }} textAlign={"right"}>
                <SoftTypography variant="caption" color="secondary" fontWeight="medium" textTransform="uppercase">
                    {summary.name}
                </SoftTypography>
            </Grid>

            <Grid xs={12} style={{ whiteSpace: "nowrap", textAlign: "right", borderTop: "1px solid black" }}><MoneyCell value={formatDecimalValue(summary.winnings)} prefix={currencySymbol} /></Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap", textAlign: "right" }}><MoneyCell value={getValueInActiveCurrency(summary.fees)} prefix={currencySymbol} /></Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap", textAlign: "right" }}><MoneyCell value={getValueInActiveCurrency(summary.totalEarnings)} prefix={currencySymbol} /></Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap", textAlign: "right" }}><MoneyCell value={formatDecimalValue(summary.proportion)} prefix="" suffix="%" useColorScheme={false} /></Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap", textAlign: "right" }}><MoneyCell value={summary.activeClubCount} prefix="" useColorScheme={false} /></Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap", textAlign: "right", borderBottom: "1px solid #17c1e8", borderTop: "1px solid #17c1e8" }}> <SoftTypography variant="caption" color="info" fontWeight="medium" textTransform="uppercase">
                &nbsp;
            </SoftTypography></Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap", textAlign: "right" }}><MoneyCell value={formatDecimalValue(summary.tokens.used)} prefix="" useColorScheme={true} /></Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap", textAlign: "right" }}><MoneyCell value={getValueInActiveCurrency(summary.tokens.cost)} prefix={currencySymbol} /></Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap", textAlign: "right" }}><MoneyCell value={getValueInActiveCurrency(summary.mttOverlay)} prefix={currencySymbol} /></Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap", textAlign: "right" }}><MoneyCell value={getValueInActiveCurrency(summary.xmttDues)} prefix={currencySymbol} /></Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap", textAlign: "right" }}><MoneyCell value={getValueInActiveCurrency(summary.manager)} prefix={currencySymbol} /></Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap", textAlign: "right" }}><MoneyCell value={getValueInActiveCurrency(summary.reporting)} prefix={currencySymbol} /></Grid>

            {showCostBreakDown ? <>
                <Grid xs={12} style={{ whiteSpace: "nowrap", textAlign: "right" }}><MoneyCell value={getValueInActiveCurrency(summary.reportingResponisbleFor)} prefix={currencySymbol} /></Grid>
                <Grid xs={12} style={{ whiteSpace: "nowrap", textAlign: "right" }}><MoneyCell value={getValueInActiveCurrency(summary.reportingResponsibilityRebate)} prefix={currencySymbol} /></Grid>
            </> : null}

            {summary.adhoc && summary.adhoc.expenses ? summary.adhoc.expenses.map((expense) => {
                return <Grid xs={12} style={{ whiteSpace: "nowrap", textAlign: "right" }}><MoneyCell value={getValueInActiveCurrency(expense.amount * -1)} prefix={currencySymbol} /></Grid>
            }) : null}

            <Grid xs={12} style={{ whiteSpace: "nowrap", textAlign: "right" }}><MoneyCell value={getValueInActiveCurrency(summary.totalExpenses)} prefix={currencySymbol} /></Grid>



            {summary.promos && summary.promos.length > 0 ? <>
                <Grid xs={12} style={{ whiteSpace: "nowrap", textAlign: "right", borderBottom: "1px solid #17c1e8", borderTop: "1px solid #17c1e8" }}> <SoftTypography variant="caption" color="info" fontWeight="medium" textTransform="uppercase">
                    &nbsp;
                </SoftTypography></Grid>
                {summary.promos.map((line) => {

                    return <Grid xs={12} style={{ whiteSpace: "nowrap", textAlign: "right" }}><MoneyCell value={getValueInActiveCurrency(-1 * line.amount)} prefix={currencySymbol} /></Grid>;
                })}
            </> : null}


            <Grid xs={12} style={{ whiteSpace: "nowrap", textAlign: "right", borderBottom: "1px solid #17c1e8", borderTop: "1px solid #17c1e8" }}> <SoftTypography variant="caption" color="info" fontWeight="medium" textTransform="uppercase">
                &nbsp;
            </SoftTypography></Grid>

            <Grid xs={12} style={{ whiteSpace: "nowrap", textAlign: "right" }}><MoneyCell value={getValueInActiveCurrency((-1 * summary.clubSettlement))} prefix={currencySymbol} /></Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap", textAlign: "right" }}><MoneyCell value={getValueInActiveCurrency((-1 * summary.unionDues))} prefix={currencySymbol} /></Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap", textAlign: "right" }}><MoneyCell value={getValueInActiveCurrency(summary.totalExpenses)} prefix={currencySymbol} /></Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap", textAlign: "right" }}><MoneyCell value={getValueInActiveCurrency(summary.referrals)} prefix={currencySymbol} /></Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap", textAlign: "right" }}><MoneyCell value={getValueInActiveCurrency(summary.loyaltyRebate)} prefix={currencySymbol} /></Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap", textAlign: "right" }}><MoneyCell value={getValueInActiveCurrency(summary.totalProfit)} prefix={currencySymbol} /></Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap", textAlign: "right", borderBottom: "1px solid #17c1e8", borderTop: "1px solid #17c1e8" }}> <SoftTypography variant="caption" color="info" fontWeight="medium" textTransform="uppercase">
                &nbsp;
            </SoftTypography></Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap", textAlign: "right" }}><MoneyCell value={getValueInActiveCurrency(summary.totalEarnings)} prefix={currencySymbol} /></Grid>

{/** 
            <Grid xs={12} style={{ whiteSpace: "nowrap", textAlign: "right" }}><MoneyCell value={getValueInActiveCurrency(summary.xmttDues)} prefix={currencySymbol} /></Grid>
            */}
            <Grid xs={12} style={{ whiteSpace: "nowrap", textAlign: "right" }}><MoneyCell value={getValueInActiveCurrency(summary.chipOffset)} prefix={currencySymbol} /></Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap", textAlign: "right" }}><MoneyCell value={getValueInActiveCurrency(summary.tax)} prefix={currencySymbol} /></Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap", textAlign: "right" }}><MoneyCell value={getValueInActiveCurrency(summary.totalExpenses)} prefix={currencySymbol} /></Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap", textAlign: "right", borderTop: "1px solid black" }}><MoneyCell value={formatDecimalValue(summary.settlement - summary.xmttDues)} prefix={currencySymbol} /></Grid>
        </Grid>
    </Item >
};

const ReportSummaryUnionsClubDetails = ({ summary, currency, currencySymbol, currencyConverter, calculator }) => {
    //we expected a pre-calculate sub union summary, where each member of clubs/activeClubs represnets sub union club

    function getValueInActiveCurrency(v) {
        return utilities.getValueInActiveCurrency(v, calculator, currency, currencyConverter);
    }

    function prepareDataForPresentation(s, table) {
        let rows = [];

        //row.sort
        if (s && s.activeClubs) {
            for (var i = 0; i < s.activeClubs.length; i++) {
                rows.push({
                    clubName: s.activeClubs[i].club.name,
                    wins: s.activeClubs[i].winnings,
                    fees: s.activeClubs[i].fees,
                    unionDues: s.activeClubs[i].calc.unionDues,
                    xmttDues: s.activeClubs[i].calc.xmttDues,
                    taxRebate: s.activeClubs[i].calc.taxRebate,
                    chips: s.activeClubs[i].calc.chipOffset,
                    referrals: s.activeClubs[i].referralBonus,
                    dividends: s.activeClubs[i].loyaltyRebate,
                    settlement: s.activeClubs[i].settlementAmount,
                });
            }
        }

        if (rows.length > 0) {
            rows.sort(function (a, b) {
                if (a.clubName.toLowerCase() < b.clubName.toLowerCase()) { return -1; }
                if (a.clubName.toLowerCase() > b.clubName.toLowerCase()) { return 1; }

                return 0;
            });
        }

        table.rows = rows;
        return table;
    }

    function getTableSchema() {
        return {
            columns: [
                { Header: "Club", accessor: "clubName", Cell: ({ value }) => <TextCell value={value} /> },
                { Header: "Winnings", accessor: "wins", Cell: ({ value }) => <MoneyCell value={getValueInActiveCurrency(value)} prefix={currencySymbol} useColorScheme={true} /> },
                { Header: "Fees", accessor: "fees", Cell: ({ value }) => <MoneyCell value={getValueInActiveCurrency(value)} prefix={currencySymbol} useColorScheme={true} /> },
                { Header: "Union Dues", accessor: "unionDues", Cell: ({ value }) => <MoneyCell value={getValueInActiveCurrency(value)} prefix={currencySymbol} useColorScheme={true} /> },
                { Header: "XMTT Dues", accessor: "xmttDues", Cell: ({ value }) => <MoneyCell value={getValueInActiveCurrency(value)} prefix={currencySymbol} useColorScheme={true} /> },
                { Header: "Chip Offset", accessor: "chips", Cell: ({ value }) => <MoneyCell value={getValueInActiveCurrency(value)} prefix={currencySymbol} useColorScheme={true} /> },
                { Header: "Tax Rebate", accessor: "taxRebate", Cell: ({ value }) => <MoneyCell value={getValueInActiveCurrency(value)} prefix={currencySymbol} useColorScheme={true} /> },
                { Header: "Referrals", accessor: "referrals", Cell: ({ value }) => <MoneyCell value={getValueInActiveCurrency(value)} prefix={currencySymbol} useColorScheme={true} /> },
                { Header: "Settlement", accessor: "settlement", Cell: ({ value }) => <MoneyCell value={getValueInActiveCurrency(value)} prefix={currencySymbol} useColorScheme={true} /> }
                //sub union nav may not work      // { Header: "details", accessor: "summary", Cell: ({ value }) => <ButtonCell id={value} onButtonClicked={unionDetailButtonClicked} icon="launch" color="secondary" /> },
            ],
            //hcontrol value
            rows: []
        };
    }

    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "Loading clubs...", null)
    const [clubDetailsTable, setClubsDetailsTable] = useState(null);

    useEffect(() => {
        if (!clubDetailsTable && summary) {
            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading union summaries...")
            showProgress();

            setClubsDetailsTable(prepareDataForPresentation(summary, getTableSchema()));
        }
        else if (clubDetailsTable) {
            progressIndicatorProps.close();
        }


    }, [clubDetailsTable, summary]);


    return <>
        <ProgressIndicator {...progressIndicatorProps} />
        {clubDetailsTable && !progressIndicatorProps.visible ?
            <DataTable table={clubDetailsTable} entriesPerPage={{ defaultValue: 1000, visible: false }} showTotalEntries={false} isHierarchical={false} />
            : null}
    </>;

};

const ReportSummaryUnions = ({ unions, clubs, summaries, reportingPeriod, union, cardroom, currency, currencySymbol, currencyConverter, calculator }) => {

    function getValueInActiveCurrency(v) {
        return utilities.getValueInActiveCurrency(v, calculator, currency, currencyConverter);
    }

    function prepareDataForPresentation(us, cs, ss, table) {

        function calculateAmountOwed(clubs) {
            if (!clubs) return 0;
            let total = 0;
            for (var i = 0; i < clubs.length; i++) {
                total += clubs[i].settlementAmount;
            }
            return total;
        }

        let rows = [];
        for (var i = 0; i < ss.length; i++) {
            if (!ss[i].union) continue;
            rows.push({
                unionName: ss[i].name,
                clubSettlement: ss[i].clubSettlement,
                unionSettlement: ss[i].settlement,
                profit: ss[i].totalProfit,
                clubsThatOweCount: ss[i].clubsThatOwe.length,
                clubsThatAreOwedCount: ss[i].clubsThatAreOwed.length,
                owedToClubs: calculateAmountOwed(ss[i].clubsThatAreOwed),
                owedByClubs: calculateAmountOwed(ss[i].clubsThatOwe),
                summary: ss[i],
                players: <ReportSummaryUnionsClubDetails summary={ss[i]} currency={currency} currencySymbol={currencySymbol} currencyConverter={currencyConverter} calculator={calculator} />
            });
        }

        table.rows = rows;
        return table;
    }
    function getTableSchema(unionDetailButtonClicked) {
        return {
            columns: [
                { Header: "Sub Union", accessor: "unionName", Cell: ({ value }) => <TextCell value={value} /> },
                { Header: "Clubs Settlement", accessor: "clubSettlement", Cell: ({ value }) => <MoneyCell value={getValueInActiveCurrency(value)} prefix={currencySymbol} useColorScheme={true} /> },
                { Header: "Profit", accessor: "profit", Cell: ({ value }) => <MoneyCell value={getValueInActiveCurrency(value)} prefix={currencySymbol} useColorScheme={true} /> },
                { Header: "Owe", accessor: "clubsThatOweCount", Cell: ({ value }) => <MoneyCell value={value} prefix="" useColorScheme={false} /> },
                { Header: "Owed", accessor: "clubsThatAreOwedCount", Cell: ({ value }) => <MoneyCell value={value} prefix="" useColorScheme={false} /> },
                { Header: "Owed to", accessor: "owedToClubs", Cell: ({ value }) => <MoneyCell value={getValueInActiveCurrency(value)} prefix={currencySymbol} useColorScheme={true} /> },
                { Header: "Owed by", accessor: "owedByClubs", Cell: ({ value }) => <MoneyCell value={getValueInActiveCurrency(value)} prefix={currencySymbol} useColorScheme={true} /> },
                { Header: "details", accessor: "summary", Cell: ({ value }) => value && value.allowNavigation ? <ButtonCell id={value} onButtonClicked={unionDetailButtonClicked} icon="launch" color="secondary" /> : null },
            ],
            collapsibleDetailAccessor: "players",
            rows: []
        };
    }

    function onUnionReportClicked(summary) {
        if (!summary) return;

        let c = summary.club;
        if (c) c.app = reportingPeriod.app;

        let u = summary.union;
        if (u) u.app = reportingPeriod.app;

        var rpClone = Object.assign({}, reportingPeriod);
        ReportUtilities.publish("/dashboards/union/report", { cardroom: cardroom, union: u, club: c, report: rpClone })
    }

    const [unionsTable, setUnionsTable] = useState(null);

    const [retryCounter, setRetryCounter] = useState(0);
    function handleRetry() {
        setRetryCounter(retryCounter + 1);
    }
    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "Loading union summaries...", handleRetry)

    useEffect(() => {
        if (!unionsTable && unions && clubs && summaries) {
            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading union summaries...")
            showProgress();

            setUnionsTable(prepareDataForPresentation(unions, clubs, summaries, getTableSchema(onUnionReportClicked)))
        }
        else if (unionsTable) {
            progressIndicatorProps.close();
        }

    }, [unionsTable, unions, clubs, summaries, retryCounter]);

    return <Card p={1}>
        <ProgressIndicator {...progressIndicatorProps} />
        {unionsTable && !progressIndicatorProps.visible ? <DataTable table={unionsTable} entriesPerPage={{ defaultValue: 10, visible: true }} showTotalEntries={true} isHierarchical={true} /> : null}
    </Card>;
};

const ReportSummaryXUnion = ({ totalSummary, xUnionSummary, currency, currencySymbol, currencyConverter, calculator }) => {

    function getValueInActiveCurrency(v) {
        return utilities.getValueInActiveCurrency(v, calculator, currency, currencyConverter);
    }
    return (totalSummary && xUnionSummary) ? <SoftBox mt={0}>
        <Grid container justifyContent="space-between">
            <Grid item xs={12}>
                <Divider />
            </Grid>
        </Grid>
        {/* Row 1 */}
        <Grid container xs={12} justifyContent="space-between">
            <Grid item xs={12} sm={4}>
                <Grid container xs={12} justifyContent="space-between">
                    <Grid item sm={12} textAlign={"left"}>
                        <Tooltip title="Cross union multi-table tournament earnings" placement="top-start">
                            <SoftTypography variant="h6" color="secondary" fontWeight="medium" textTransform="capitalize">
                                XMTT Earnings
                            </SoftTypography>
                        </Tooltip>
                    </Grid>
                    <Grid item sm={12} textAlign={"left"}>
                        <SoftTypography variant="caption" fontWeight="medium" style={{ whiteSpace: "nowrap" }}>
                            <MoneyCell value={getValueInActiveCurrency(xUnionSummary.xmttEarnings)} prefix={currencySymbol} />
                        </SoftTypography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={4}>
                <Grid container xs={12} justifyContent="space-between">
                    <Grid item sm={12} textAlign={"center"}>
                        <Tooltip title="Cross union multi-table tournament dues" placement="top">
                            <SoftTypography variant="h6" color="secondary" fontWeight="medium" textTransform="capitalize">
                                XMTT Dues
                            </SoftTypography>
                        </Tooltip>
                    </Grid>
                    <Grid item sm={12} textAlign={"center"}>
                        <SoftTypography variant="caption" fontWeight="medium" style={{ whiteSpace: "nowrap" }}>
                            <MoneyCell value={getValueInActiveCurrency(totalSummary.xmttDues)} prefix={currencySymbol} />
                        </SoftTypography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={4}>
                <Grid container xs={12} justifyContent="space-between">
                    <Grid item sm={12} textAlign={"right"}>
                        <Tooltip title="Bad beat jackpot profits & loss" placement="right-start">
                            <SoftTypography variant="h6" color="secondary" fontWeight="medium" textTransform="capitalize">
                                Jackpot P&L
                            </SoftTypography>
                        </Tooltip>
                    </Grid>
                    <Grid item sm={12} textAlign={"right"}>
                        <SoftTypography variant="caption" fontWeight="medium" style={{ whiteSpace: "nowrap" }}>
                            <MoneyCell value={getValueInActiveCurrency(xUnionSummary.jackpotPnL)} prefix={currencySymbol} />
                        </SoftTypography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>

        <Grid container justifyContent="space-between">
            <Grid item xs={12}>
                <Divider />
            </Grid>
        </Grid>
        {/* Row 2 */}
        <Grid container xs={12} justifyContent="space-between">
            <Grid item xs={12} sm={4}>
                <Grid container xs={12} justifyContent="space-between">
                    <Grid item sm={12} textAlign={"left"}>
                        <Tooltip title="Total expenses incurred by the union" placement="top-start">
                            <SoftTypography variant="h6" color="secondary" fontWeight="medium" textTransform="capitalize">
                                Total Expenses
                            </SoftTypography>
                        </Tooltip>
                    </Grid>
                    <Grid item sm={12} textAlign={"left"}>
                        <SoftTypography variant="caption" fontWeight="medium" style={{ whiteSpace: "nowrap" }}>
                            <MoneyCell value={getValueInActiveCurrency(totalSummary.totalExpenses)} prefix={currencySymbol} />
                        </SoftTypography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={4}>
                <Grid container xs={12} justifyContent="space-between">
                    <Grid item sm={12} textAlign={"center"}>
                        <Tooltip title="Chip offset" placement="top">
                            <SoftTypography variant="h6" color="secondary" fontWeight="medium" textTransform="capitalize">
                                Chip Offset
                            </SoftTypography>
                        </Tooltip>
                    </Grid>
                    <Grid item sm={12} textAlign={"center"}>
                        <SoftTypography variant="caption" fontWeight="medium" style={{ whiteSpace: "nowrap" }}>
                            <MoneyCell value={getValueInActiveCurrency(totalSummary.chipOffset)} prefix={currencySymbol} />
                        </SoftTypography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={4}>
                <Grid container xs={12} justifyContent="space-between">
                    <Grid item sm={12} textAlign={"right"}>
                        <Tooltip title="Tax rebate" placement="right-start">
                            <SoftTypography variant="h6" color="secondary" fontWeight="medium" textTransform="capitalize">
                                Tax Rebate
                            </SoftTypography>
                        </Tooltip>
                    </Grid>
                    <Grid item sm={12} textAlign={"right"}>
                        <SoftTypography variant="caption" fontWeight="medium" style={{ whiteSpace: "nowrap" }}>
                            <MoneyCell value={getValueInActiveCurrency(totalSummary.tax)} prefix={currencySymbol} />
                        </SoftTypography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>


        <Grid container justifyContent="space-between">
            <Grid item xs={12}>
                <Divider />
            </Grid>
        </Grid>


        {/* Row 3 */}
        <Grid container xs={12} justifyContent="space-between">
            <Grid item xs={12} sm={4}>
                <Grid container xs={12} justifyContent="space-between">
                    <Grid item sm={12} textAlign={"left"}>
                        <Tooltip title="Multi-table guarantee gap" placement="top-start">
                            <SoftTypography variant="h6" color="secondary" fontWeight="medium" textTransform="capitalize">
                                MTT Overlay
                            </SoftTypography>
                        </Tooltip>
                    </Grid>
                    <Grid item sm={12} textAlign={"left"}>
                        <SoftTypography variant="caption" fontWeight="medium" style={{ whiteSpace: "nowrap" }}>
                            <MoneyCell value={getValueInActiveCurrency(totalSummary.mttOverlay)} prefix={currencySymbol} />
                        </SoftTypography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={4}>
                <Grid container xs={12} justifyContent="space-between">
                    <Grid item sm={12} textAlign={"center"}>
                        <Tooltip title="Spin'n'go offset" placement="top-start">
                            <SoftTypography variant="h6" color="secondary" fontWeight="medium" textTransform="capitalize">
                                Spin N Go Offset
                            </SoftTypography>
                        </Tooltip>
                    </Grid>
                    <Grid item sm={12} textAlign={"center"}>
                        <SoftTypography variant="caption" fontWeight="medium" style={{ whiteSpace: "nowrap" }}>
                            <MoneyCell value={getValueInActiveCurrency(totalSummary.spinOffset)} prefix={currencySymbol} />
                        </SoftTypography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={4}>
                <Grid container xs={12} justifyContent="space-between">
                    <Grid item sm={12} textAlign={"right"}>
                        <Tooltip title="Bubble protection" placement="top">
                            <SoftTypography variant="h6" color="secondary" fontWeight="medium" textTransform="capitalize">
                                Bubble Protection
                            </SoftTypography>
                        </Tooltip>
                    </Grid>
                    <Grid item sm={12} textAlign={"right"}>
                        <SoftTypography variant="caption" fontWeight="medium" style={{ whiteSpace: "nowrap" }}>
                            <MoneyCell value={getValueInActiveCurrency(xUnionSummary.bubbleProtection)} prefix={currencySymbol} />
                        </SoftTypography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>

        <Grid container justifyContent="space-between">
            <Grid item xs={12}>
                <Divider />
            </Grid>
        </Grid>
    </SoftBox> : null;
};

const ReportSummarySelf = ({ cardroom, union, reportingPeriod, summary, summaries, currency, currencySymbol, currencyConverter, calculator, onLineEdit, onLineDelete, showCostBreakDown }) => {

    return (reportingPeriod && summaries) ? <Stack direction={"row"} mt={2} mb={2} style={{ backgroundColor: 'transparent', shadowOpacity: 0 }}>
        <ReportSummaryCard cardroom={cardroom} union={union} reportingPeriod={reportingPeriod} summary={summary} legend={true} currency={currency}
            currencySymbol={currencySymbol} currencyConverter={currencyConverter} calculator={calculator}
            onLineEdit={onLineEdit} onLineDelete={onLineDelete} showCostBreakDown={showCostBreakDown} />
        <Stack direction={"row"}
            divider={<Divider orientation="vertical" flexItem />}
            spacing={1}
            style={{ overflowX: "auto" }}>
            {summaries.map((s, index) => {
                return <ReportSummaryCard cardroom={cardroom} union={union} reportingPeriod={reportingPeriod} summary={s} legend={false} currency={currency} currencySymbol={currencySymbol}
                    currencyConverter={currencyConverter} calculator={calculator} onLineEdit={onLineEdit} onLineDelete={onLineDelete} showCostBreakDown={showCostBreakDown} />;
            })}
        </Stack>
    </Stack> : null;
};

const ReportSummary = ({ cardroom, union, reportingPeriod, summary, currency, currencyConverter, mode, callback }) => {

    const cardroomLogo = cardroom && cardroom.hasLogo ? apiConfig.images.endpoint + "/casino/" + cardroom.id + ".png" : defaultCasino;
    const appLogo = reportingPeriod && reportingPeriod.app ? apiConfig.images.endpoint + "/app/" + reportingPeriod.app.id + ".png" : null;


    const [currencySymbol, setCurrencySymbol] = useState(null);

    function isCurrencyMaskingEnabled() {
        if (!context) return false;
        return context.isEnabled(features.currency.mask, 0);
    }

    useEffect(() => {
        if (true === isCurrencyMaskingEnabled()) {
            setCurrencySymbol("");
            return;
        }

        if (currency) setCurrencySymbol(currency.symbol);
    }, [currency, currencyConverter]);

    const [retryCounter, setRetryCounter] = useState(0);
    function handleRetry() {
        setRetryCounter(retryCounter + 1);
    }
    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "Loading report details...", handleRetry)

    //build summaries
    const [summaries, setSummaries] = useState(null);
    const [clubs, setClubs] = useState(null);
    const [unions, setUnions] = useState(null);
    const [promotions, setPromotions] = useState(null);

    // const [tokens, setTokens] = useState(null);
    const [xUnionSummary, setXUnionSummary] = useState(null);

    const [netSettlement, setNetSettlement] = useState(0);

    const [context, actions, features] = useCardroomContext(cardroom);
    function canEditAdhocLine() {

        if (!context) return false;

        if (cardroom.id != reportingPeriod.casinoId) return false;

        if (!union) return false;

        if (cardroom.id != union.managedByCasinoId) return false;

        return context.isAllowed(actions.club.reports.adhoc.edit);
    }

    const [openAdhocLineEditor, adhocLineEditorProps] = useDialog();
    const [currentAdhocLine, setCurrentAdhocLine] = useState(null);
    const [currentAdhocAction, setCurrentAdhocAction] = useState(null);

    function onAdhocLineActionWasCompleted(line, action) {

        let wasFound = false;
        if (!reportingPeriod.adhocLineItems) reportingPeriod.adhocLineItems = [];
        for (var i = 0; i < reportingPeriod.adhocLineItems.length; i++) {
            if (reportingPeriod.adhocLineItems[i].id === line.id) {

                if (action === "delete") {
                    reportingPeriod.adhocLineItems.splice(i, 1);
                }
                else {
                    reportingPeriod.adhocLineItems[i] = Object.assign(reportingPeriod.adhocLineItems[i], line);
                }
                wasFound = true;
                break;
            }
        }

        if (false == wasFound && action !== "delete") {
            reportingPeriod.adhocLineItems.push(line);
        }

        setSummaries(null); //should trigger a rebuild
        setCurrentAdhocLine(null);
        setCurrentAdhocAction(null);
    }

    function onLineEditRequested(line) {
        if (!line) return;

        setCurrentAdhocAction("edit");
        setCurrentAdhocLine(line);
        openAdhocLineEditor();
    }

    function onLineDeleteRequested(line) {
        if (!line) return;

        setCurrentAdhocAction("delete");
        setCurrentAdhocLine(line);
        openAdhocLineEditor();
    }

    function onAddNewLine() {
        var line = {
            id: "00000000-0000-0000-0000-000000000000",
            title: "",
            amount: 0,
            category: "Expense",
            ownedByReportId: reportingPeriod.id
        };

        setCurrentAdhocAction("add");
        setCurrentAdhocLine(line);
        openAdhocLineEditor();
    }

    const clubRequest = useFetchWithMsal({
        scopes: apiConfig.club.scopes.read,
    });

    const casinoRequest = useFetchWithMsal({
        scopes: apiConfig.casino.scopes.read,
    });

    const dataLoader = useUnionDataLoader();

    useEffect(() => {
        if (!clubs && reportingPeriod) {

            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading club details...")
            showProgress();

            dataLoader.report.loadClubs(clubRequest, cardroom, reportingPeriod, union, enableCache, (clubs) => {
                //success
                if (clubs) {
                    setClubs(clubs);
                }
            }, (ex) => {
                //error
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load club details, plese try again...");
            });
        }

    }, [clubRequest.execute, clubs, reportingPeriod, retryCounter]);

    useEffect(() => {
        if (!unions && union && clubs) {

            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading unions...")
            showProgress();

            dataLoader.report.loadUnions(clubRequest, cardroom, union, enableCache, (unions) => {
                //success
                setUnions([...unions, union])
            }, (ex) => {
                //error
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load unions, plese try again...");
            });
        }

    }, [clubRequest.execute, clubs, unions, union, retryCounter]);

    useEffect(() => {
        if (!promotions && unions && union && clubs) {

            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading promotions...")
            showProgress();

            // [HttpGet("{casinoId:Guid}/promotions", Name = "GetCasinoPromotions")]
            casinoRequest.execute("GET", apiConfig.casino.endpoint + "/" + cardroom.id + "/promotions").then((promos) => {
                if (promos)
                    setPromotions(promos)
                else setPromotions([]);

            }).catch((ex) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load promotions, plese try again...");
            });

        }

    }, [casinoRequest.execute, clubs, unions, promotions, union, retryCounter]);


    useEffect(() => {
        // tokens are not needed in xunion mode
        if (!xUnionSummary && (mode === "xunion" || mode === "unions") && reportingPeriod && clubs && unions && promotions) {

            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading cross union summary...")
            showProgress();

            dataLoader.report.loadXUnionSummary(clubRequest, cardroom, union, reportingPeriod, enableCache, (xuSummary) => {
                //success
                setXUnionSummary(xuSummary);
            }, (ex) => {
                //error
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load cross union summary, plese try again...");
            });

        }

    }, [clubRequest.execute, mode, xUnionSummary, clubs, unions, promotions, reportingPeriod, retryCounter]);

    function buildSummaries(period, us, cs, summary, xuSummary, promos) {
        let rows = [];

        let totalTokens = summary.totalAllowedTokens;
        let reportingCosts = summary.cost;
        if (!reportingCosts) {
            reportingCosts = {
                base: reportingPeriod.cost,
                responsibleFor: 0,
                responsibilityRebate: 0
            };
        }

        let uMap = [];
        for (var i = 0; i < us.length; i++) {
            if (!uMap[us[i].id]) {
                uMap[us[i].id] = us[i];
            }
        }

        function createSummaryStub(name, union) {
            return {
                name: name,
                union: union,
                winnings: 0,
                fees: 0,
                insurance: 0,
                spinOffset: 0,
                mttRealized: 0,
                mttImplied: 0,
                activeClubCount: 0,
                bubbleProtection: 0,
                leaderboardCost: 0,
                xmttFees: 0,
                xmttPnL: 0,
                jackpotPnL: 0,
                tokens: {
                    used: 0,
                    cost: 0
                },
                mttOverlay: 0,
                xmttDues: 0,
                manager: 0,
                reporting: 0,
                reportingResponisbleFor: 0,
                reportingResponsibilityRebate: 0,
                totalExpenses: 0,
                unionDues: 0,
                totalProfit: 0,
                totalEarnings: 0,
                referrals: 0,
                loyaltyRebate: 0,
                chipOffset: 0,
                tax: 0,
                clubs: [],
                clubsThatOwe: [],
                clubsThatAreOwed: [],
                activeClubs: [],
                percentage: 0,
                proportion: 0,
                settlement: 0,
                adhocExpenses: 0,
                promoAllocation: 0,
                clubSettlement: 0,
                allowNavigation: true
            };
        }

        function updateTotals(totalSummary, unionSummary) {
            if (!totalSummary || !unionSummary) return;

            totalSummary.winnings += unionSummary.winnings;
            totalSummary.fees += unionSummary.fees;
            totalSummary.totalEarnings += unionSummary.totalEarnings;
            totalSummary.xmttDues += unionSummary.xmttDues;

            unionSummary.activeClubCount = unionSummary.activeClubs.length;
            totalSummary.activeClubCount += unionSummary.activeClubCount;

            totalSummary.unionDues += unionSummary.unionDues;
            totalSummary.chipOffset += unionSummary.chipOffset;
            totalSummary.tax += unionSummary.tax;
            totalSummary.spinOffset += unionSummary.spinOffset;
            totalSummary.jackpotPnL += unionSummary.jackpotPnL;

            totalSummary.referrals += unionSummary.referrals;
            totalSummary.loyaltyRebate += unionSummary.loyaltyRebate;

            totalSummary.clubSettlement += unionSummary.clubSettlement;
        }

        function updateShareBasedFees(totalSummary, unionSummary) {
            if (!totalSummary || !unionSummary) return;
            unionSummary.percentage = unionSummary.fees / totalSummary.fees;
            unionSummary.proportion = unionSummary.percentage * 100;

            //totalSummary.percentage += unionSummary.percentage; //should equal 1 at the end
            //totalSummary.proportion += unionSummary.proportion; //should equal 100

            unionSummary.tokens.used = totalSummary.tokens.used * unionSummary.percentage;
            unionSummary.tokens.cost = unionSummary.tokens.used * union.tokenUnitCost;

            unionSummary.reporting = totalSummary.reporting * unionSummary.percentage;
            unionSummary.manager = totalSummary.manager * unionSummary.percentage;
            unionSummary.mttOverlay = totalSummary.mttOverlay * unionSummary.percentage;

            unionSummary.totalExpenses = unionSummary.tokens.cost + unionSummary.mttOverlay + unionSummary.xmttDues + unionSummary.manager + unionSummary.reporting + unionSummary.adhocExpenses;
            totalSummary.totalExpenses += unionSummary.totalExpenses; // - unionSummary.adhocExpenses; //backout adhoc as we're already tracking them in totalAdhocExpenses

            totalSummary.promoAllocation += unionSummary.promoAllocation;

            unionSummary.settlement = unionCalculator.unionSettlement(unionSummary);
            totalSummary.settlement += unionSummary.settlement;

            unionSummary.totalProfit = unionSummary.settlement - unionSummary.clubSettlement; // unionSummary.settlement - unionSummary.clubSettlement + unionSummary.totalExpenses;
            totalSummary.totalProfit += unionSummary.totalProfit;
        }

        function updateAdhocLines(totalSummary, unionSummaries, rootSummary) {

            if (rootSummary.adhoc) {

                //delete all adhocs that we don't want, do not belong to current report AND are not shareable
                const lines = [];

                for (var i = 0; i < rootSummary.adhoc.expenses.length; i++) {
                    if (rootSummary.adhoc.expenses[i].ownedByReportId != reportingPeriod.id && rootSummary.adhoc.expenses[i].splitWithSubEntity !== true) continue;

                    var line = rootSummary.adhoc.expenses[i];

                    lines.push(line);

                    for (var j = 0; j < rows.length; j++) {
                        //look at each union column and see if the expense is splittable, assign column value, if not, set it to zero
                        if (!rows[j].adhoc) rows[j].adhoc = {};
                        if (!rows[j].adhoc.expenses) rows[j].adhoc.expenses = [];

                        var lineClone = Object.assign({}, line);

                        if (line.splitWithSubEntity === true) lineClone.amount = lineClone.amount * rows[j].percentage;
                        else {
                            if (rows[j].union) lineClone.amount = 0;
                        }

                        rows[j].totalExpenses -= lineClone.amount;

                        rows[j].adhoc.expenses.push(lineClone);
                    }

                }

                rootSummary.adhoc.expenses = lines;
            }
        }


        function updatePromoLines(totalSummary, unionSummaries, rootSummary) {

            //we need to decide which promos are contributed to by which unions --- BBJ is contributed to by ea Umbrella

            if (rootSummary.promos) {

                //delete all adhocs that we don't want, do not belong to current report AND are not shareable
                const lines = [];

                for (var i = 0; i < rootSummary.promos.length; i++) {

                    var line = rootSummary.promos[i];

                    lines.push(line);

                    for (var j = 0; j < rows.length; j++) {
                        //look at each union column and see if the expense is splittable, assign column value, if not, set it to zero
                        if (!rows[j].promos) rows[j].promos = [];


                        var lineClone = Object.assign({}, line);

                        //if bbj promo, figure out how to split, otherwise, it's 0

                        lineClone.amount = 0;

                        rows[j].promos.push(lineClone);
                    }

                }
                //totalSummary.promos = lines;
                //rootSummary.promos = lines;

                totals.promos = rootSummary.promos;
            }



        }


        let sMap = [];
        let totals = createSummaryStub("Union Totals", null);

        totals.percentage = summary.feeRatio;
        totals.proportion = totals.percentage * 100;

        totals.reporting = -1 * reportingCosts.base * totals.percentage; // - reportingCosts.responsibleFor + reportingCosts.responsibilityRebate;
        totals.reportingResponisbleFor = reportingCosts.responsibleFor;
        totals.reportingResponsibilityRebate = reportingCosts.responsibilityRebate;

        //THE LOGIC IS CORRECT< however, when navigating from PARENT union to CHILD union, this value is modfied by the child union...
        //which is really odd since totals is a local variable....
        totals.mttOverlay = summary.mttOverlay * summary.feeRatio; // * totals.percentage;

        totals.tokens.used = totalTokens * summary.feeRatio;
        totals.tokens.cost = union.tokenUnitCost * totals.tokens.used;
        totals.manager = union.unionManagerFee * -1 * summary.feeRatio;

        let directs = createSummaryStub("Unaffiliated", union);
        directs.allowNavigation = false;

        for (var i = 0; i < cs.length; i++) {
            if (!cs[i] || !cs[i].club) continue;

            let u = uMap[cs[i].club.parentUnionId];
            let s = null;
            if (u) {
                if (u.id === union.id) {
                    s = directs;
                }
                else {
                    if (!sMap[u.id]) {
                        s = createSummaryStub(u.name, u);
                        sMap[u.id] = s;
                        rows.push(s);
                    }
                    s = sMap[u.id];
                }
            }

            if (!s) continue; //this would happen if we navigated to sub union that still has all clubs cached, so we're just skipping clubs that don't belong...

            s.clubs.push(cs[i]);

            s.winnings += cs[i].winnings;
            s.fees += cs[i].fees;
            s.xmttFees += cs[i].xmttFees;
            s.xmttPnL += cs[i].xmttPnL;
            s.jackpotPnL += cs[i].jackpotPnL;
            s.insurance += cs[i].insurance;
            s.spinOffset += cs[i].spinNGoOffset;
            s.mttRealized += cs[i].mttRealized;
            s.mttImplied += cs[i].mttImplied;
            s.bubbleProtection += cs[i].bubbleProtection;
            s.leaderboardCost += cs[i].leaderboardCost;
            s.referrals += cs[i].referralBonus;
            s.loyaltyRebate += cs[i].loyaltyRebate;
            s.clubSettlement += cs[i].settlementAmount;

            cs[i].calc = {
                earnings: unionCalculator.earnings(cs[i]),
                unionDues: unionCalculator.unionDues(cs[i]),
                xmttDues: unionCalculator.xmttDues(cs[i]),
                chipOffset: unionCalculator.chipOffset(cs[i]),
                taxRebate: unionCalculator.ecoTax(cs[i])
            };

            s.totalEarnings += cs[i].calc.earnings;
            s.unionDues += cs[i].calc.unionDues;
            s.xmttDues += cs[i].calc.xmttDues;
            s.chipOffset += cs[i].calc.chipOffset;
            s.tax += cs[i].calc.taxRebate;

            if (cs[i].winnings > 0 || cs[i].fees || cs[i].xmttFees || cs[i].settlementAmount) //add other properties to test for active
            {
                s.activeClubs.push(cs[i]);
            }

            if (cs[i].settlementAmount > 0) {
                s.clubsThatAreOwed.push(cs[i]);
            }
            else if (cs[i].settlementAmount < 0) {
                s.clubsThatOwe.push(cs[i]);
            }
        }

        //update totals & percentages
        if (directs.clubs.length > 0) {
            updateTotals(totals, directs);
        }

        for (var i = 0; i < rows.length; i++) {
            updateTotals(totals, rows[i]);
        }

        //now that totals have been calc'd, we can calculate percentage & update fees based on percentage
        //update totals & percentages
        if (directs.clubs.length > 0) {
            updateShareBasedFees(totals, directs);
        }

        for (var i = 0; i < rows.length; i++) {
            updateShareBasedFees(totals, rows[i]);
        }


        //summary.settlementAmount = totals.settlement;
        if (mode === "self" || mode === "unions" || union.isVirtual) {
            setNetSettlement(totals.settlement);
        }
        else if (mode === "xunion") {
            //these calcs apply only to root level union...
            let net = xuSummary.xmttEarnings;

            net += totals.xmttDues;
            net += totals.totalExpenses;
            net += totals.chipOffset;
            net += totals.tax;

            net -= xuSummary.jackpotPnL;
            net -= totals.mttOverlay;
            net -= xuSummary.bubbleProtection;
            net -= totals.spinOffset;

            setNetSettlement(net);
        }

        if (rows.length > 0) {
            rows.sort(function (a, b) {
                //reverse order
                if (a.name.toLowerCase() < b.name.toLowerCase()) { return 1; }
                if (a.name.toLowerCase() > b.name.toLowerCase()) { return -1; }
                return 0;
            });
        }

        rows.unshift(totals);
        if (directs.activeClubs.length > 0 && rows.length > 1) {
            rows.push(directs);
        }


        summary.promos = unionCalculator.preparePromotionalLines(period, promos);

        summary.adhoc = unionCalculator.categorizeLineItems(period);

        updateAdhocLines(totals, rows, summary);
        updatePromoLines(totals, rows, summary);

        if (!isNaN(totals.totalAdhocExpenses)) totals.totalExpenses += -1 * totals.totalAdhocExpenses * totals.percentage;

        //update reporting total costs
        totals.reporting = totals.reporting - reportingCosts.responsibleFor + reportingCosts.responsibilityRebate;
        summary.showCostBreakdown = Boolean(reportingCosts.responsibleFor > 0);


        return rows;
    }

    const unionCalculator = useUnionCalculator();

    function getValueInActiveCurrency(v) {
        return utilities.getValueInActiveCurrency(v, unionCalculator, currency, currencyConverter);
    }

    useEffect(() => {
        if (!summaries && reportingPeriod && unions && clubs && promotions && summary && (mode == "self" || ((mode === "xunion" || mode === "unions") && xUnionSummary))) {

            setSummaries(buildSummaries(reportingPeriod, unions, clubs, summary, xUnionSummary, promotions));

            if (callback) {
                callback({
                    addNewLine: onAddNewLine
                });
            }

            progressIndicatorProps.close();
        }

    }, [reportingPeriod, summaries, xUnionSummary, unions, clubs, promotions, summary, mode]);

    //TODO: add tooltips to each heading explaing how it was put together
    return <Card>
        {!progressIndicatorProps.visible ? <>
            <Grid container justifyContent="center">
                <Grid item xs={12} md={mode === "unions" ? 12 : 10} lg={mode === "unions" ? 12 : 8}>
                    <Card >
                        <SoftBox p={3}>
                            <Grid container xs={12} justifyContent="space-between">
                                {union && reportingPeriod ? <>
                                    <Grid item sm={4} sx={{ display: { xs: 'none', sm: 'block' } }}>
                                        <SoftBox width="100%" textAlign="left" mt={2}>
                                            <SoftBox textAlign="center" width="max-content">
                                                <SoftBox component="img" src={appLogo} width="96px" style={{}} p={1} mb={1} />
                                                <SoftTypography variant="h6" fontWeight="medium">
                                                    {reportingPeriod.app.name}
                                                </SoftTypography>
                                            </SoftBox>
                                        </SoftBox>
                                    </Grid>
                                    <Grid item sm={4} xs={12}>
                                        <SoftBox width="100%" textAlign={{ xs: "center", md: "center" }} mt={2}>

                                            <SoftTypography variant="h6" color="secondary" fontWeight="medium" textTransform="uppercase">
                                                {(mode === "self" || mode === "unions") ? "Settlement" : "Union Responsibility"}
                                            </SoftTypography>

                                            <SoftTypography variant="h6" color="secondary" fontWeight="medium" textTransform="uppercase">
                                                <MoneyCell value={getValueInActiveCurrency(netSettlement)} prefix={currencySymbol} />
                                            </SoftTypography>
                                        </SoftBox>
                                    </Grid>
                                    <Grid item sm={4} sx={{ display: { xs: 'none', sm: 'block' } }}>
                                        <SoftBox justifyContent="right" textAlign="right" mt={2}>
                                            <SoftBox ml="auto" textAlign="center" width="max-content">
                                                <SoftBox component="img" src={cardroomLogo} width="96px" style={{}} p={1} mb={1} />
                                                <SoftTypography variant="h6" fontWeight="medium">
                                                    {union ? union.name : null}
                                                </SoftTypography>
                                            </SoftBox>
                                        </SoftBox>
                                    </Grid></> : null}
                            </Grid>

                            {mode === "self" && canEditAdhocLine() ? <AdhocLineDialog {...adhocLineEditorProps} cardroom={cardroom} union={union} reportingPeriod={reportingPeriod} line={currentAdhocLine} action={currentAdhocAction} onLineActionComplete={onAdhocLineActionWasCompleted} /> : null}


                            {summaries ? <>
                                {(mode === "self") ? <ReportSummarySelf cardroom={cardroom} union={union} reportingPeriod={reportingPeriod} summary={summary} summaries={summaries} currency={currency} currencySymbol={currencySymbol} currencyConverter={currencyConverter} calculator={unionCalculator} onLineEdit={onLineEditRequested} onLineDelete={onLineDeleteRequested} showCostBreakDown={summary.showCostBreakdown} /> : null}
                                {(mode === "xunion") ? <ReportSummaryXUnion xUnionSummary={xUnionSummary} totalSummary={summaries[0]} currency={currency} currencySymbol={currencySymbol} currencyConverter={currencyConverter} calculator={unionCalculator} /> : null}
                                {(mode === "unions") ? <ReportSummaryUnions unions={unions} clubs={clubs} summaries={summaries} reportingPeriod={reportingPeriod} cardroom={cardroom} currency={currency} currencySymbol={currencySymbol} currencyConverter={currencyConverter} calculator={unionCalculator} /> : null}
                            </>
                                : null}
                        </SoftBox>
                    </Card>


                </Grid>
            </Grid>
        </> : <ProgressIndicator {...progressIndicatorProps} />
        }
        <Divider />
    </Card >;
};

ReportSummary.propTypes = {
    cardroom: PropTypes.object.isRequired,
    union: PropTypes.object.isRequired,
    reportingPeriod: PropTypes.object.isRequired,
    mode: PropTypes.oneOf(["self", "xunion", "unions"])
};

const UnionReport = ({ cardroom, union, reportingPeriod, summary, currency, currencyConverter }) => {

    const [retryCounter, setRetryCounter] = useState(0);

    function handleRetry() {
        setRetryCounter(retryCounter + 1);
    }

    const [currencySymbol, setCurrencySymbol] = useState(null);
    function isCurrencyMaskingEnabled() {
        if (!context) return false;
        return context.isEnabled(features.currency.mask, 0);
    }

    useEffect(() => {
        if (true === isCurrencyMaskingEnabled()) {
            setCurrencySymbol("");
            return;
        }

        if (currency) setCurrencySymbol(currency.symbol);
    }, [currency, currencyConverter]);

    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "Loading report details...", handleRetry)

    const [context, actions, features] = useCardroomContext(cardroom);

    function supportsSubUnions() {
        if (!union) return false;

        if (union.isVirtual) return false;

        return ((union.features & 16) > 0);
    }

    const clubRequest = useFetchWithMsal({
        scopes: apiConfig.club.scopes.read,
    });

    const unionCalculator = useUnionCalculator();

    const [openPaymentsMapDialog, paymentsMapDialogProps] = useDialog();

    function createPaymentMap() {
        openPaymentsMapDialog();
    }

    const [summaryConfig, setSummaryConfig] = useState(null);

    function summaryCallBack(cfg) {
        setSummaryConfig(cfg);
    }

    function addNewAdhocLine() {
        if (!summaryConfig) return;
        if (!summaryConfig.addNewLine) return;
        summaryConfig.addNewLine();
    }

    function isAddNewAdhocLineAllowed() {
        if (!context) return false;

        if (cardroom.id != reportingPeriod.casinoId) return false;

        if (!union) return false;

        if (cardroom.id != union.managedByCasinoId) return false;

        return context.isAllowed(actions.club.reports.adhoc.add);
    }

    const tabs = [];

    tabs.push({
        title: { text: "Summary", icon: "summarize" },
        header: null,
        body: <ReportSummary cardroom={cardroom} union={union} reportingPeriod={reportingPeriod} summary={summary} currency={currency} currencyConverter={currencyConverter} mode="xunion" callback={summaryCallBack} />
    });

    tabs.push({
        title: { text: "Details", icon: "dashboard" },
        header: isAddNewAdhocLineAllowed() ? <SoftButton onClick={addNewAdhocLine}><Icon>add</Icon>&nbsp;New Adhoc Line</SoftButton> : null,
        body: <ReportSummary cardroom={cardroom} union={union} reportingPeriod={reportingPeriod} summary={summary} currency={currency} currencyConverter={currencyConverter} mode="self" callback={summaryCallBack} />
    });


    if (supportsSubUnions()) {
        tabs.push({
            title: { text: "Unions", icon: "beach_access" },
            header: null,
            body: <ReportSummary cardroom={cardroom} union={union} reportingPeriod={reportingPeriod} summary={summary} currency={currency} currencyConverter={currencyConverter} mode="unions" callback={summaryCallBack} />
        });
    }

    tabs.push({
        title: { text: "Clubs", icon: "nightlife" },
        header: reportingPeriod ? <> <ReportingPeriodPaymentsMapDialog {...paymentsMapDialogProps} cardroom={cardroom} union={union} reportingPeriod={reportingPeriod} clubs={reportingPeriod.clubs} currency={currency} currencySymbol={currencySymbol} currencyConverter={currencyConverter} calculator={unionCalculator} />
            <SoftButton variant="outlined" size="small" color="dark" onClick={createPaymentMap}><SoftBox whiteSpace="nowrap"><Icon size="lg">turn_right</Icon>&nbsp;Optimize Payments</SoftBox></SoftButton></> : null,
        body: <ReportUnionClubs cardroom={cardroom} union={union} reportingPeriod={reportingPeriod} currency={currency} currencyConverter={currencyConverter} />
    });

    return <SoftBox pt={2}><AdaptiveTab switchToMobileOnSize="md" tabs={tabs} /></SoftBox>;
};

UnionReport.defaultProps = {

};

UnionReport.propTypes = {
    cardroom: PropTypes.object.isRequired,
    union: PropTypes.object.isRequired,
    reportingPeriod: PropTypes.object.isRequired,
    summary: PropTypes.object.isRequired,
    currency: PropTypes.object.isRequired,
    currencyConverter: PropTypes.object.isRequired
};


export default UnionReport;